import { useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { _get_member_missions_and_quests_Api } from "src/DAL/member/Member";
import { dd_date_format } from "src/utils/constants";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import MemberQuestUpdateData from "./MemberQuestUpdateData";
import { useLocation, useNavigate } from "react-router-dom";

export default function MemberQuests({ questList, setQuestList }) {
  const [openQuestUpdatePopup, setOpenQuestUpdatePopup] = useState(false);
  const [questUpdatePopupData, setQuestUpdatePopupData] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleMissionDetail = (value) => {
    navigate(`${pathname}/${value?.mission_info?._id}`, {
      state: value,
    });
  };

  const handleUpdateQuestAccess = (value) => {
    setQuestUpdatePopupData(value);
    setOpenQuestUpdatePopup(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Update Quest Access",
      icon: "ant-design:lock",
      handleClick: handleUpdateQuestAccess,
    },
  ];

  const TABLE_HEAD = [
    // {
    //   id: "action",
    //   label: "Action",
    //   type: "action",
    //   MENU_OPTIONS: "MENU_OPTIONS",
    // },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: " Title",
      handleClick: handleMissionDetail,
      className: "cursor-pointer",
    },
    {
      id: "mission_start_date",
      label: "Start Date",
      renderData: (row) => {
        return (
          <span>
            {row?.mission_start_date
              ? dd_date_format(row?.mission_start_date)
              : "N/A"}
          </span>
        );
      },
    },
    {
      id: "mission_end_date",
      label: "End Date",
      renderData: (row) => {
        return (
          <span>
            {row?.mission_end_date
              ? dd_date_format(row?.mission_end_date)
              : "N/A"}
          </span>
        );
      },
    },
    {
      id: "mission_status",
      label: "Status",
    },
  ];

  return (
    <>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={questList}
          className="card-with-background"
          MENU_OPTIONS={MENU_OPTIONS}
          pagePagination={true}
        />
      </div>
      <GeneralPopUpModel
        open={openQuestUpdatePopup}
        setOpen={setOpenQuestUpdatePopup}
        title={"Update Quest Access"}
        componentToPassDown={
          <>
            <MemberQuestUpdateData
              questUpdatePopupData={questUpdatePopupData}
              setOpenQuestUpdatePopup={setOpenQuestUpdatePopup}
              questList={questList}
              setQuestList={setQuestList}
            />
          </>
        }
      />
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { baseUri, socketBaseUri } from "src/config/config";
import {
  _get_admin_time_zone_localStorage,
  _get_consultant_from_localStorage,
  _get_user_from_localStorage,
  _wheel_of_life_setting_localStorage,
} from "../DAL/localstorage/LocalStorage";

import io from "socket.io-client";
const CreateContentSetting = React.createContext();
const get_admin_time_zone_localStorage = _get_admin_time_zone_localStorage();
const get_user = _get_user_from_localStorage();
const get_consultant = _get_consultant_from_localStorage();
const wheel_of_life_setting = _wheel_of_life_setting_localStorage();

// socket-initialization

let socket = {};
if (get_user._id) {
  socket = io(socketBaseUri + `?user_id=${get_user._id}&role=delegate`);
  // socket = io(
  //   "http://192.168.1.72:4615/" + `?user_id=${get_user._id}&role=delegate`
  // );
}

export const useContentSetting = () => useContext(CreateContentSetting);
export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */
  const START_SOCKET_FORCEFULLY = (user_id) => {
    socket = io(socketBaseUri + `?user_id=${user_id}&role=delegate`);
  };

  const STOP_SOCKET_FORCEFULLY = (user_id) => {
    socket.disconnect();
  };

  const [adminTimeZone, setAdminTimeZone] = useState(
    get_admin_time_zone_localStorage
  );

  const [userInfo, setUserInfo] = useState(get_user);
  const [consultantInfo, setConsultantInfo] = useState(get_consultant);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [consultantDetail, setConsultantDetail] = useState();
  const [isLocal] = useState(window.location.pathname.includes("localhost"));
  const [googleClientId, setGoogleClientId] = useState("");
  const [stripePublicKey, setStripePublicKey] = useState("");
  const [stripeKeyObject, setStripeKeysObject] = useState({});
  const [isEnableGoogleCalender, setisEnableGoogleCalender] = useState();
  const [bankDetails, SetBankDetails] = useState("");
  const [pollWinnerDescription, setPollWinnerDescription] = useState("");
  const [isAccess, setIsAccess] = useState({});
  const [tinymceKey, settinymceKey] = useState({});
  const [wheelOfLifeSetting, setWheelOfLifeSetting] = useState(
    wheel_of_life_setting
  );
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleAdminTimeZone = (val) => {
    setAdminTimeZone(val);
  };
  const handleSetUserInfo = (val) => {
    setUserInfo(val);
  };
  const handleConsultantInfo = (val) => {
    setConsultantInfo(val);
  };
  const handleNotificationsCount = (val) => {
    setNotificationsCount(val);
  };

  const handleUnreadMessageCount = (val) => {
    setUnreadMessageCount(val);
  };

  const handleWheelOfLifeSetting = (val) => {
    setWheelOfLifeSetting(val);
  };

  const collection = {
    adminTimeZone,
    setConsultantDetail,
    setStripePublicKey,
    stripePublicKey,
    consultantDetail,
    setAdminTimeZone,
    handleSetUserInfo,
    userInfo,
    wheelOfLifeSetting,
    setUserInfo,
    consultantInfo,
    handleConsultantInfo,
    handleAdminTimeZone,
    handleUnreadMessageCount,
    handleNotificationsCount,
    setNotificationsCount,
    socket,
    START_SOCKET_FORCEFULLY,
    notificationsCount,
    STOP_SOCKET_FORCEFULLY,
    handleWheelOfLifeSetting,
    stripeKeyObject,
    setStripeKeysObject,
    googleClientId,
    setGoogleClientId,
    isEnableGoogleCalender,
    setisEnableGoogleCalender,
    setIsAccess,
    isAccess,
    tinymceKey,
    settinymceKey,
    bankDetails,
    SetBankDetails,
    pollWinnerDescription,
    setPollWinnerDescription,
    isLocal,
  };

  useEffect(() => {
    if (userInfo?._id && !isConnected) {
      socket.emit("live_event_room", userInfo._id);
      setIsConnected(true);
    }
    return () => {};
  }, [userInfo, isConnected]);

  useEffect(() => {
    userInfo.is_executive = true;
  }, [userInfo]);

  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}

import React from "react";
import { s3baseUrl } from "src/config/config";
import MissionLevelsOverviewTab from "src/components/MissionLevels/MissionLevelsOverviewTab";
import ReactVideoPlayer from "src/components/_dashboard/GeneralComponents/ReactVideoPlayer";
import MissionQuestSchedules from "src/components/MissionLevels/MissionQuestSchedules";
import QuestCardForReport from "src/components/MissionLevels/QuestCardForReport";

export default function QuestOverview(props) {
  const { missionBadges, missionDetail, handleClick } = props;

  const TABS_DATA = {
    handleClick,
    schedules: missionDetail.mission_schedules,
    missionDetail,
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row mt-2">
          <div className={`col-12 ${"col-md-8"}`}>
            <div id="video_detail_image_box">
              {missionDetail.video_url ? (
                <ReactVideoPlayer url={missionDetail.video_url} />
              ) : (
                <img
                  src={s3baseUrl + missionDetail.image?.thumbnail_1}
                  className="img-fluid w-100"
                />
              )}
            </div>
          </div>

          <div className="col-md-4">
            <QuestCardForReport
              missionDetail={missionDetail}
              missionBadges={missionBadges}
              hideEranedBadges={true}
            />
          </div>

          {missionDetail.detailed_description && (
            <div className="col-12">
              <MissionLevelsOverviewTab {...TABS_DATA} />
            </div>
          )}
          {missionDetail.mission_schedules.length > 0 && (
            <div className="col-md-12  mt-3">
              <div className="row">
                <div
                  className="col-12 schedule-heading pb-0"
                  id="schedule-heading-box"
                >
                  <h2 style={{ fontSize: "20px" }} className="mt-2 mb-2">
                    {missionDetail.content_settings?.schedule_heading}
                  </h2>
                </div>
              </div>
              <MissionQuestSchedules {...TABS_DATA} missionSchedules={{}} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

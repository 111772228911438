import React, { useState } from "react";
import { CircularProgress, Modal } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useEffect } from "react";
import {
  get_feeds_detail,
  get_review_feeds_api,
} from "src/DAL/Community/Community";
import FeedDetailPopup from "./FeedDetailPopup";
import { dd_date_time_format } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import FeedMenuOptions from "./components/FeedMenuOptions";
import CreatePost from "./CreatePost";
import CustomAvatarColorCode from "src/components/CustomAvatarColorCode";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ReviewFeeds() {
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { userInfo } = useContentSetting();
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [feedsList, setFeedsList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [selectedObject, setSelectedObject] = useState({});
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [feedType, setFeedType] = useState("general");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [feedAppearBy, setFeedAppearBy] = useState("public");

  const handleFeedDetail = (data) => {
    setSelectedObject(data);
    setOpenDetailModal(true);
  };

  const handleFeedEdit = (selected_feed) => {
    setSelectedObject(selected_feed);
    setOpenCreateModal(true);
  };

  const handle_feed_data = (feed) => {
    let set_feed_created_for = feed.feed_created_for;
    if (set_feed_created_for == "general") {
      set_feed_created_for = "The Source";
    } else if (set_feed_created_for == "delegate") {
      set_feed_created_for = "The Cosmos";
    } else if (set_feed_created_for == "event") {
      set_feed_created_for = `Event`;
      if (feed.dynamite_event?.title) {
        set_feed_created_for += ` (${feed.dynamite_event?.title})`;
      }
    } else if (set_feed_created_for == "mission") {
      set_feed_created_for = `Mission`;
      if (feed.mission?.title) {
        set_feed_created_for += ` (${feed.mission?.title})`;
      }
    } else if (set_feed_created_for == "program") {
      set_feed_created_for = `Programme`;
      if (feed.program_id?.title) {
        set_feed_created_for += ` (${feed.program_id?.title})`;
      }
    }

    let review_reason = feed.review_info?.reason;
    if (review_reason?.length > 50) {
      review_reason = review_reason.substring(0, 50) + "...";
    }
    let feeds_description = feed.description;
    if (feeds_description?.length > 50) {
      feeds_description = feeds_description.substring(0, 50) + "...";
    }

    return {
      ...feed,
      name: feed.action_info?.name,
      color_code: feed?.badge_level_info?.color_code,
      review_reason,
      created_at: dd_date_time_format(feed.createdAt),
      view_detail: "View Detail",
      feeds_description,
      set_feed_created_for,
      table_avatar: {
        src: feed?.action_info?.profile_image
          ? s3baseUrl + feed.action_info?.profile_image
          : "",
        alt: feed.action_info.name,
      },
    };
  };

  const handleSuccessUpdate = async (post) => {
    handleUpdateSpecificFeed(post._id);
  };

  const onSuccess = (type, post) => {
    if (type === "delete") {
      setFeedsList((old) => old.filter((feed) => feed._id !== post._id));
    } else {
      setFeedsList((old) =>
        old.map((feed) => {
          if (feed._id === post._id) {
            return { ...feed, is_feature: !feed.is_feature };
          }
          return feed;
        })
      );
    }
  };

  const handleUpdateSpecificFeed = async (id) => {
    const result = await get_feeds_detail(id);
    if (result.code === 200) {
      setFeedsList((old) =>
        old.map((feed) => {
          if (feed._id === selectedObject._id) {
            return handle_feed_data(result.feeds);
          }
          return feed;
        })
      );
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    {
      label: "Action",
      renderData: (feed) => (
        <FeedMenuOptions
          feed={feed}
          handleEdit={handleFeedEdit}
          onSuccess={onSuccess}
          list_type={"review"}
        />
      ),
    },
    { id: "number", label: "#", type: "number" },
    {
      id: "profile_image",
      label: "Image",
      renderData: (row) => {
        return (
          <CustomAvatarColorCode
            alt={row.first_name}
            src={row?.table_avatar?.src}
            colorCode={row?.color_code}
          />
        );
      },
    },
    {
      id: "name",
      label: "Name",
      handleClick: handleFeedDetail,
      className: "cursor-pointer",
    },
    {
      id: "feeds_description",
      label: "Description",
      handleClick: handleFeedDetail,
      className: "cursor-pointer",
    },
    { id: "set_feed_created_for", label: "Created For" },
    {
      id: "feed_appear_by",
      label: "Appear by",
      className: "text-capitalize",
    },
    {
      id: "view_detail",
      label: "View Detail",
      className: "view-review-feed",
      handleClick: handleFeedDetail,
    },
    {
      id: "review_reason",
      label: "Reason",
      handleClick: handleFeedDetail,
      className: "cursor-pointer",
    },
    {
      id: "created_at",
      label: "Created At",
    },
  ];

  const getFeedsList = async () => {
    setIsLoading(true);
    const result = await get_review_feeds_api(page, rowsPerPage, searchText);
    if (result.code === 200) {
      const feeds = result.feeds.map((feed, index) => handle_feed_data(feed));
      setFeedsList(feeds);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("member_rows", event.target.value);
    localStorage.setItem("member_page_number", 0);
    setPageCount(1);
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleCloseDetailBox = () => {
    setOpenDetailModal(false);
  };

  const handleSuccessDelete = (post) => {
    setOpenDetailModal(false);
    setFeedsList((old) => old.filter((feed) => feed._id !== post._id));
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getFeedsList();
  };

  useEffect(() => {
    getFeedsList();
  }, [page, rowsPerPage]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Review Posts</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={feedsList}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
            />
          </div>
        </div>
      </div>
      <Modal
        open={openDetailModal}
        onClose={handleCloseDetailBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="feed-detail-popup"
      >
        <>
          <div className="row post-detail-box">
            <span
              className="cross-show-all-likes-model"
              onClick={handleCloseDetailBox}
            >
              x
            </span>
          </div>
          <FeedDetailPopup
            handleCloseDetailBox={handleCloseDetailBox}
            post_id={selectedObject._id}
            feed={selectedObject}
            handleSuccessDelete={handleSuccessDelete}
            openDetailModal={openDetailModal}
            list_type="review"
          />
        </>
      </Modal>
      {openCreateModal && (
        <CreatePost
          setFeedType={setFeedType}
          feedType={feedType}
          openCreateModal={openCreateModal}
          setOpenCreateModal={setOpenCreateModal}
          getFeedsListing={() => {}}
          userInfo={userInfo}
          selectedFeed={selectedObject}
          formAction={"EDIT"}
          setFormAction={() => {}}
          handleSuccessUpdate={handleSuccessUpdate}
          list_type="review"
          setFeedAppearBy={setFeedAppearBy}
          feedAppearBy={feedAppearBy}
        />
      )}
    </>
  );
}

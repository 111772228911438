import { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { CircularProgress } from "@mui/material";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { makeStyles } from "@material-ui/core/styles";
import { ContextPGIMode, usePGIMode } from "../../Hooks/PGIModeContext";
import { witTokeninitapi } from "src/DAL/Login/Login";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { useSnackbar } from "notistack";
import { full_page_popup_routes } from "../../utils/constants";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 80;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  // overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  // overflow: "auto",
  width: screen.width > 991 ? `calc(100vw - 310px)` : "100vw",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const FullPaeStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
}));

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

const handle_embed_tinymce_key = (key) => {
  var head = document.head;
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `https://cdn.tiny.cloud/1/${key}/tinymce/5/tinymce.min.js`;
  head.appendChild(script);
};
const handle_add_google_analytic_key = (key) => {
  var head = document.head;
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `https://www.googletagmanager.com/gtag/js?id=${key}`;
  head.appendChild(script);
};
const handle_append_pixel_code_header = (scriptContent) => {
  try {
    // Create a temporary container to hold the script content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = scriptContent;

    // Loop through the child nodes of tempDiv and append them to the head
    Array.from(tempDiv.childNodes).forEach((node) => {
      if (node.nodeType === Node.COMMENT_NODE) {
        // If it's a comment node, append it as a comment
        const comment = document.createComment(node.nodeValue);
        document.head.appendChild(comment);
      } else if (
        node.nodeType === Node.ELEMENT_NODE &&
        node.tagName === "SCRIPT"
      ) {
        // If it's a script node, create a new script element
        const scriptTag = document.createElement("script");

        // Copy all attributes (like "async" and "src")
        Array.from(node.attributes).forEach((attr) =>
          scriptTag.setAttribute(attr.name, attr.value)
        );

        // If the script has inline JavaScript content, set it
        if (node.innerHTML) {
          scriptTag.innerHTML = node.innerHTML;
        }

        // Append the script to the head
        document.head.appendChild(scriptTag);
      }
    });
  } catch (error) {
    console.error("Failed to append pixel code:", error);
  }
};
const handle_append_pixel_code_body = (noscriptContent) => {
  try {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = noscriptContent;

    Array.from(tempDiv.childNodes)
      .reverse()
      .forEach((node) => {
        document.body.insertBefore(node, document.body.firstChild);
      });
  } catch (error) {
    console.error("Failed to append noscript content:", error);
  }
};

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const { setBadgeLevels } = usePGIMode();
  const {
    setConsultantDetail,
    handleSetUserInfo,
    handleAdminTimeZone,
    setStripePublicKey,
    handleNotificationsCount,
    handleUnreadMessageCount,
    setStripeKeysObject,
    setGoogleClientId,
    setisEnableGoogleCalender,
    setIsAccess,
    handleConsultantInfo,
    settinymceKey,
    SetBankDetails,
    setPollWinnerDescription,
  } = useContentSetting();
  const classes = useStyles();
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  } else if (!localStorage.getItem("user_data")) {
    localStorage.clear();
    return <Navigate to="/login"> </Navigate>;
  }
  const getContentSetting = async () => {
    const result = await witTokeninitapi();
    if (result.code == 200) {
      handle_embed_tinymce_key(result.tinymce_key);
      if (result.access_object) {
        setIsAccess(result.access_object);
      }
      let StripeKeysObject = {
        live_publish_key: result.site_setting.live_publish_key,
        sandBox_publish_key: result.site_setting.sandBox_publish_key,
      };
      setBadgeLevels(result?.access_object?.badge_levels);
      settinymceKey(result.tinymce_key);
      setConsultantDetail(result);
      SetBankDetails(result?.consultant_setting?.bank_payment_details);
      localStorage.setItem(`user_data`, JSON.stringify(result.consultant));
      localStorage.setItem(`admin_time_zone`, JSON.stringify(result.time_zone));
      handleSetUserInfo(result.consultant);
      if (result.site_setting.client_id) {
        setGoogleClientId(result.site_setting.client_id);
      }
      if (
        result.site_setting &&
        result.site_setting.is_calendar_enabled_for_delegate
      ) {
        setisEnableGoogleCalender(
          result.site_setting.is_calendar_enabled_for_delegate
        );
      }
      if (result.consultant_setting.pixel_code_header) {
        handle_append_pixel_code_header(
          result.consultant_setting.pixel_code_header
        );
      }
      if (result.consultant_setting.pixel_code_body) {
        handle_append_pixel_code_body(
          result.consultant_setting.pixel_code_body
        );
      }
      if (result.consultant_setting.analytic_google_code) {
        handle_add_google_analytic_key(
          result.consultant_setting.analytic_google_code
        );
      }
      handleAdminTimeZone(result.time_zone);
      handleConsultantInfo(result.consultant_setting);
      handleNotificationsCount(result.unread_notification_count);
      handleUnreadMessageCount(result.unread_message_count);

      setPollWinnerDescription(result.poll_winner_description);
      if (result.site_setting.stripe_mode === "sandBox") {
        setStripePublicKey(result.site_setting.sandBox_publish_key);
      } else {
        setStripePublicKey(result.site_setting.live_publish_key);
      }
      setStripeKeysObject(StripeKeysObject);
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };
  useEffect(() => {
    getContentSetting();
  }, []);
  if (loading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let is_calendar_page = full_page_popup_routes.some((route) =>
    pathname.startsWith(route)
  );

  return (
    <RootStyle>
      {is_calendar_page ? (
        <>
          <FullPaeStyle>
            <Outlet />
          </FullPaeStyle>
        </>
      ) : (
        <>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <DashboardSidebar
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
          <MainStyle>
            <Outlet />
          </MainStyle>
        </>
      )}
    </RootStyle>
  );
}

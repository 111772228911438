import { invokeApi } from "../../bl_libs/invokeApi";

export const memberAndProgramListing = async (data) => {
  const requestObj = {
    path: `api/consultant/clients_and_programs_for_delegate/list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberWithSearch = async (search, include_members) => {
  const requestObj = {
    path: `api/member/members_list_for_select/delegate?search_text=${
      search ? search : ""
    }&&include_members=${include_members ? include_members : "nurture"}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteNote = async (data) => {
  const requestObj = {
    path: `api/member/remove_personal_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteLeadStatusHistory = async (data) => {
  const requestObj = {
    path: `api/lead_status/delete_lead_status/for_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addPersonalNote = async (data) => {
  const requestObj = {
    path: `api/member/update_personal_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const sendNotificationUserApi = async (data, id) => {
  const requestObj = {
    path: `api/feeds/send_feed_reminder/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addPersonalNoteComment = async (data) => {
  const requestObj = {
    path: `api/member/add_note/from_portal`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addPersonalNoteFromChat = async (data) => {
  const requestObj = {
    path: `api/member/add_note/from_chat`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const noteListing = async (id) => {
  const requestObj = {
    path: `api/member/list_personal_note/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const EditNote = async (data) => {
  const requestObj = {
    path: `api/member/edit_personal_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const MemberListing = async (data) => {
  const requestObj = {
    path: `api/member/active_member_list_by_consultant`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const nurture_member_listing = async (page, limit, filterName, data) => {
  const requestObj = {
    path: `api/consultant/member_list_for/associate?page=${page}&limit=${limit}&search_text=${
      filterName ? filterName : ""
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const wheelOfLifeMemberDetail = async (id) => {
  const requestObj = {
    path: `api/member/member_wheel_of_life_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberSubscriberListingApi = async (id, page, limit, search) => {
  const requestObj = {
    path: `api/member/event_subscriber_list/member_id/${id}?page=${page}&limit=${limit}&search_text=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteSubscriberMemberApi = async (id) => {
  const requestObj = {
    path: `api/event_subscriber/delete_event_subscriber/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberWheelOfLifeQuestionList = async (id) => {
  const requestObj = {
    path: `api/consultant/question_answer_sale_page_by_member_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const productListApi = async (id) => {
  const requestObj = {
    path: `api/product`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getNinetyDayQuestionApi = async (data) => {
  const requestObj = {
    path: `api/ninteen_day_vision_questions/get_question`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberGroupListApi = async (id) => {
  const requestObj = {
    path: `api/member/member_group_list/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberDetailApi = async (id, data) => {
  const requestObj = {
    path: `api/member/${id}?start_date=${data.start_date}&end_date=${data.end_date}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const sendWhatsappMessageApi = async (data) => {
  const requestObj = {
    path: `app/send_whatsapp_message`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const send_sms_to_member_api = async (data) => {
  const requestObj = {
    path: `api/consultant/send_sms_to_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AllMemberListing = async (data) => {
  const requestObj = {
    path: `api/member/member_list_by_consultant`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AllMemberForPageNameListing = async (page, limit, filterName) => {
  const requestObj = {
    path: `api/event_subscriber/subscriber_list_for_member?page=${page}&limit=${limit}&&search_text=${
      filterName ? filterName : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const allMember_ForPageNameListing_New = async (
  page,
  limit,
  filterName,
  _formData
) => {
  const requestObj = {
    path: `api/event_subscriber/subscriber_list_for_member_v1?page=${page}&limit=${limit}&&search_text=${
      filterName ? filterName : ""
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: _formData,
  };
  return invokeApi(requestObj);
};
export const allMemberListingForDelegate = async (
  page,
  limit,
  data,
  _formData
) => {
  const requestObj = {
    path: `api/member/member_list_for_delegate?page=${page}&limit=${limit}&search_text=${
      data ? data : ""
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: _formData,
  };
  return invokeApi(requestObj);
};
export const subscriber_list_for_member_with_filter = async (
  page,
  limit,
  filterName,
  _formData
) => {
  const requestObj = {
    path: `api/event_subscriber/subscriber_list_for_member_with_filter?page=${page}&limit=${limit}&&search_text=${
      filterName ? filterName : ""
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: _formData,
  };
  return invokeApi(requestObj);
};
export const paymentTemplateListApi = async (id) => {
  const requestObj = {
    path: `api/payment_template`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const LeadStatusListing = async (id) => {
  const requestObj = {
    path: `api/lead_status/list_lead_status/for_member/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const memberAndGroupsListApi = async (search) => {
  const requestObj = {
    path: `api/consultant/groups_and_members/list_for_delegate?search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_member_filter_data_list_api = async (
  search,
  delegate_search,
  filter_on_tab_name
) => {
  const requestObj = {
    path: `api/consultant/filter_data_for/delegate?search_text=${
      search ? search : ""
    }&delegate_search_text=${
      delegate_search ? delegate_search : ""
    }&filter_on_tab_name=${filter_on_tab_name ? filter_on_tab_name : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_member_from_proggram_event_Api = async (data) => {
  const requestObj = {
    path: `api/member/assign_program_members_to_events_for/delegate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_member_missions_and_quests_Api = async (data) => {
  const requestObj = {
    path: `api/member/get/member_missions_and_quests`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_member_quest = async (data) => {
  const requestObj = {
    path: `api/member/update/update_member_quest`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

import React, { useEffect, useState } from "react";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import MemberMissions from "./MemberMissions";
import MemberQuests from "./MemberQuests";
import ActiveLastBreadcrumb from "src/components/BreadCrums";
import { _get_member_missions_and_quests_Api } from "src/DAL/member/Member";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { show_proper_words } from "src/utils/constants";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ManageMissionsTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const classes = useStyles();
  const [Info, setInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [missionList, setMissionList] = useState([]);
  const [questList, setQuestList] = useState([]);
  const { pathname } = useLocation();
  const navigate_to = `${pathname.split("/")[1]}`;
  let tab_value = 0;
  let find_tab = localStorage.getItem("tab_store_value_of_mission_quest");
  if (find_tab) {
    tab_value = JSON.parse(find_tab);
  }
  const [tabValue, setTabValue] = useState(+tab_value);

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem(
      "tab_store_value_of_mission_quest",
      JSON.stringify(newValue)
    );
  };

  const getMissionList = async () => {
    setIsLoading(true);
    let postData = {
      member_id: params?.member_id,
    };
    const result = await _get_member_missions_and_quests_Api(postData);
    if (result.code === 200) {
      setInfo(result?.member ?? {});
      const missions_data = result.missions.map((mission) => {
        return {
          ...mission,
          title: mission?.mission_info?.title,
          mission_status: mission?.mission_status
            ? show_proper_words(mission?.mission_status)
            : "N/A",
          status_mission: mission?.mission_status,
        };
      });

      const quests_data = result.quests.map((quest) => {
        return {
          ...quest,
          title: quest?.mission_info?.title,
          mission_status: quest?.mission_status
            ? show_proper_words(quest?.mission_status)
            : "N/A",
          status_mission: quest?.mission_status,
        };
      });
      setMissionList(missions_data);
      setQuestList(quests_data);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABS_OPTIONS = [
    {
      title: "Missions",
      component: <MemberMissions missionList={missionList} />,
    },
    {
      title: "Quests",
      component: (
        <MemberQuests questList={questList} setQuestList={setQuestList} />
      ),
    },
  ];

  let breadCrumbMenu = [
    {
      title: `${show_proper_words(navigate_to)} ${
        Info ? `(${Info?.first_name} ${Info?.last_name})` : ""
      }`,
      navigation: `/${navigate_to}`,
      active: false,
    },
    {
      title: `Manage Mission`,
      active: true,
    },
  ];

  useEffect(() => {
    getMissionList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
        <div className="col-12 mt-2">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChangeTabs}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageMissionsTab;

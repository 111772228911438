import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { useEffect } from "react";
import { _update_member_quest } from "src/DAL/member/Member";
import { api_date_format } from "src/utils/constants";

export default function MemberQuestUpdateData({
  questList,
  setQuestList,
  questUpdatePopupData,
  setOpenQuestUpdatePopup,
}) {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const [inputs, setInputs] = useState({
    mission_start_date: moment(),
    mission_end_date: moment(),
  });

  const handleChangeDateRange = (event, type) => {
    setInputs((values) => ({
      ...values,
      [type]: event,
    }));
  };

  const updateListObject = () => {
    const newList = questList.map((item) => {
      if (item._id === questUpdatePopupData?._id) {
        return {
          ...item,
          mission_start_date: moment(inputs.mission_start_date),
          mission_end_date: moment(inputs.mission_end_date),
        };
      }
      return item;
    });
    setQuestList(newList);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let startDate = moment().format("YYYY-MM-DD");
    let endDate = moment().format("YYYY-MM-DD");

    if (inputs?.mission_start_date) {
      startDate = api_date_format(inputs.mission_start_date);
    }
    if (inputs?.mission_end_date) {
      endDate = api_date_format(inputs.mission_end_date);
    }

    let postData = {
      member_id: params?.member_id,
      quest_id: questUpdatePopupData?.mission_info?._id,
      start_date: startDate,
      end_date: endDate,
    };

    const result = await _update_member_quest(postData);

    if (result.code === 200) {
      updateListObject();
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenQuestUpdatePopup(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setInputs((values) => ({
      ...values,
      ...questUpdatePopupData,
    }));
  }, [questUpdatePopupData]);

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Quest Start Date"
                name="mission_start_date"
                value={inputs.mission_start_date || moment()}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(e) => handleChangeDateRange(e, "mission_start_date")}
                renderInput={(params) => (
                  <TextField {...params} className="inputs-fields" required />
                )}
              />
            </LocalizationProvider>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Quest End Date"
                name="mission_end_date"
                value={inputs.mission_end_date || moment()}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                minDate={
                  inputs.mission_start_date
                    ? new Date(inputs.mission_start_date)
                    : new Date()
                }
                onChange={(e) => handleChangeDateRange(e, "mission_end_date")}
                renderInput={(params) => (
                  <TextField {...params} className="inputs-fields" required />
                )}
              />
            </LocalizationProvider>
          </div>

          <div className="text-end mt-4">
            <button disabled={isLoading} className="small-contained-button">
              {isLoading ? "Updating..." : "Update"}{" "}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

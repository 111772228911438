import {
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React from "react";
export default function NotifyUsersSettings(props) {
  const { NotificationsSetting, setNotificationsSetting } = props;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setNotificationsSetting((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleChangeStatus = (name, value) => {
    setNotificationsSetting((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return (
    <div className="create-poll-box">
      <div className="row">
        <div className="col-12">
          <div className="mt-1 mb-1 textarea-autosize-box">
            <div className="ps-1 allow-multiple-options mb-2 mt-2">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={NotificationsSetting?.notify_users}
                    onChange={(e) =>
                      handleChangeStatus("notify_users", e.target.checked)
                    }
                  />
                }
                label="Notify Users ?"
              />
            </div>
            {NotificationsSetting?.notify_users && (
              <>
                <div className="poll-question-item">
                  <div className="mb-2">
                    <TextField
                      variant="outlined"
                      id="time"
                      label="Notification Statement"
                      type="text"
                      className="mt-1"
                      size="small"
                      name="notification_statement"
                      required={true}
                      value={NotificationsSetting.notification_statement}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder={`Notification Description*`}
                    className="text-color"
                    required
                    value={NotificationsSetting?.notification_description}
                    minRows={3}
                    name="notification_description"
                    onChange={(e) => handleChange(e)}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      background: "transparent",
                      paddingTop: "7px",
                      paddingBottom: "7px",
                      paddingLeft: "15px",
                      color: "white",
                      fontSize: "15px",
                      border: "1px solid var(--input-border-color)",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import ShowAllReports from "../ShowAllReports";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import AddAsInternalNote from "../AddAsInternalNote";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import { useSnackbar } from "notistack";
import {
  approve_feed_api,
  delete_feed_action,
  pin_feed_action,
} from "src/DAL/Community/Community";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import NotifyUsersPopup from "../NotifyUsersPopup";

export default function FeedMenuOptions(props) {
  const { feed, handleEdit, onSuccess, list_type, feed_action_type } = props;
  const { isAccess, userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const { handleAddChat } = useChat();
  const [actionType, setActionType] = useState("");
  const [reportedPopUp, setReportedPopUp] = useState(false);
  const [personalNotePopup, setpersonalNotePopup] = useState(false);
  const [openNotifyUsers, setopenNotifyUsers] = useState(false);
  const handleOpenNotifyUsers = () => {
    setopenNotifyUsers(true);
  };
  const handleAgreeAction = (action) => {
    setActionType(action);
  };
  const handleReportedUsers = () => {
    setReportedPopUp(true);
  };
  const handleReportedUsersCLose = () => {
    setReportedPopUp(false);
  };
  const handleSendMessage = async () => {
    handleAddChat(feed.action_info?.action_id);
  };
  const handleINternalNote = () => {
    setpersonalNotePopup(true);
  };
  const isChatAllow = () => {
    if (
      isAccess.is_chat_allowed &&
      feed.action_info.action_id !== userInfo._id &&
      feed.action_info.action_by == "member_user"
    ) {
      return true;
    }
    return false;
  };

  const handleApprove = async () => {
    setActionType("");
    let result = await approve_feed_api(feed._id);
    if (result.code === 200) {
      onSuccess("delete", feed);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDelete = async () => {
    setActionType("");
    const result = await delete_feed_action(feed._id);
    if (result.code === 200) {
      enqueueSnackbar("Post deleted successfully", { variant: "success" });
      onSuccess("delete", feed);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handlePinUnpin = async () => {
    let action_type = actionType;
    setActionType("");

    const formData = { feed: feed._id };
    if (feed.is_feature) {
      formData.action = "unfeature";
    } else {
      formData.action = "feature";
    }
    const result = await pin_feed_action(formData);
    if (result.code === 200) {
      onSuccess(action_type, feed);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleAction = () => {
    if (actionType === "delete") {
      handleDelete();
      return;
    }
    if (actionType === "approve") {
      handleApprove();
      return;
    }
    handlePinUnpin();
  };

  let MENU_OPTIONS = [];
  let allowed_action = isAccess.edit_delete_option_in_source_all_source_feeds;

  if (feed.is_self || (allowed_action && list_type !== "the_cosmos")) {
    const EDIT_DELETE = [
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: () => handleAgreeAction("delete"),
      },
    ];

    if (feed.feed_type === "poll") {
      if (feed.poll_info.poll_status !== "expired" && feed.is_self) {
        MENU_OPTIONS.push(...EDIT_DELETE);
      }
    } else if (feed.feed_type === "survey") {
      if (feed.survey_info.survey_status !== "expired" && feed.is_self) {
        MENU_OPTIONS.push(...EDIT_DELETE);
      }
    } else {
      MENU_OPTIONS.push(...EDIT_DELETE);
    }
  }
  if (
    feed.is_self &&
    isAccess.notify_users_on_create_post &&
    (list_type == "all_source" || list_type == "the_source")
  ) {
    MENU_OPTIONS.push({
      label: "Notify Users",
      icon: "streamline:ringing-bell-notification-solid",
      handleClick: () => handleOpenNotifyUsers(),
    });
  }
  if (list_type === "review") {
    MENU_OPTIONS.push({
      label: "Approve",
      icon: "ix:to-be-published",
      handleClick: () => handleAgreeAction("approve"),
    });
  } else if (feed?.is_reported) {
    MENU_OPTIONS.push({
      label: "Reported By",
      icon: "material-symbols:report-outline",
      handleClick: handleReportedUsers,
    });
  } else if (isAccess.feed_pin_unpin_option && !feed.is_reported) {
    MENU_OPTIONS.push({
      label: feed.is_feature == false ? "Pin" : "Unpin",
      icon: "akar-icons:pin",
      handleClick: () => handleAgreeAction(feed.is_feature ? "unpin" : "pin"),
    });
  }

  if (isChatAllow()) {
    MENU_OPTIONS.push({
      label: "Message",
      icon: "material-symbols:send",
      handleClick: handleSendMessage,
    });
  }
  if (
    list_type === "event" &&
    feed.feed_type !== "poll" &&
    feed.feed_type !== "survey"
  ) {
    MENU_OPTIONS.push({
      label: "Add As Personel Notes",
      icon: "akar-icons:edit",
      handleClick: handleINternalNote,
    });
  }

  return (
    <>
      {MENU_OPTIONS.length > 0 && (
        <CustomPopover menu={MENU_OPTIONS} data={feed} />
      )}
      {reportedPopUp === true && (
        <ShowAllReports
          openLikesModal={reportedPopUp}
          handleCloseSimpleBox={handleReportedUsersCLose}
          post_id={feed._id}
        />
      )}
      <GeneralPopUpModel
        open={personalNotePopup}
        setOpen={setpersonalNotePopup}
        title={"Add As Personal Notes"}
        componentToPassDown={
          <AddAsInternalNote
            selectedObject={feed}
            setOpen={setpersonalNotePopup}
          />
        }
      />{" "}
      <GeneralPopUpModel
        open={openNotifyUsers}
        setOpen={setopenNotifyUsers}
        title={"Notify Users"}
        componentToPassDown={
          <NotifyUsersPopup
            selectedObject={feed}
            setOpen={setopenNotifyUsers}
          />
        }
      />
      <CustomConfirmation
        open={Boolean(actionType)}
        setOpen={() => setActionType("")}
        title={`Are you sure you want to ${actionType} this post?`}
        handleAgree={handleAction}
      />
    </>
  );
}

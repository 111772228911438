import React, { useState } from "react";
import {
  Card,
  Typography,
  Button,
  Grid,
  Box,
  Tooltip,
  Avatar,
} from "@mui/material";
import { achieve, MissionCoin, rewards } from "src/assets";
import { s3baseUrl } from "src/config/config";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import RewardsPopup from "./RewardsPopup";
import { fShortenNumber } from "src/utils/formatNumber";

const MissionCardCompleteForReport = ({
  missionDetail,
  missionDashboaerd,
  missionBadges,
  hideDuration,
  hideEranedBadges,
}) => {
  const [showInfoPopup, setShowInfoPopup] = useState([]);

  return (
    <>
      {/* <h4
        className="title-source-journey mb-2 "
        style={{
          color: "#ffd889",
        }}
      >
        Rewards & Badges{" "}
      </h4> */}

      <div className="row">
        <div className="col-12 col-md-3  pe-0">
          <div className="bg-info-of-mission text-center w-100 d-flex justify-content-between bg-info-of-mission-2 mt-2 transparent-background">
            <div className="title-mission d-flex">
              <img
                src={MissionCoin}
                style={{
                  width: "20px",
                }}
              />
              <span
                className="ms-1"
                style={{
                  color: "white",
                }}
              >
                Achievable Coins
              </span>
            </div>

            <div className="info-of-mission-text">
              {fShortenNumber(missionDetail?.rewarded_coins)}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 pe-0">
          <div className="bg-info-of-mission text-center w-100 d-flex justify-content-between bg-info-of-mission-2 mt-2 transparent-background">
            <div className="title-mission d-flex">
              <img
                src={MissionCoin}
                style={{
                  width: "20px",
                }}
              />
              <span
                className="ms-1"
                style={{
                  color: "white",
                }}
              >
                Coins Earned
              </span>
            </div>

            <div className="info-of-mission-text">
              {fShortenNumber(missionDetail?.attracted_coins)}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 pe-0">
          <div className="bg-info-of-mission text-center w-100 d-flex justify-content-between bg-info-of-mission-2 mt-2 transparent-background">
            <div className="title-mission d-flex">
              <img
                src={rewards}
                style={{
                  width: "20px",
                  height: "190x",
                }}
              />
              <span
                className="ms-1"
                style={{
                  color: "white",
                }}
              >
                Achievable Badges
              </span>
            </div>
            {missionDetail.badge_configration.length > 0 && (
              <div className="info-of-mission-text">
                <div className="d-flex align-items-center">
                  <Tooltip
                    title={
                      missionDetail.badge_configration[0].title +
                      " " +
                      missionDetail.badge_configration[0].no_of_badges +
                      "x"
                    }
                  >
                    <img
                      src={
                        s3baseUrl +
                        missionDetail.badge_configration[0]?.general_icon
                          ?.thumbnail_1
                      }
                      alt=""
                      style={{
                        width: "19px",
                        borderRadius: "2px",
                      }}
                      className="badge-image"
                    />
                  </Tooltip>
                  {missionDetail.badge_configration.length - 1 > 0 && (
                    <Tooltip title={"View More"}>
                      <span
                        className="ms-1"
                        style={{
                          cursor: "pointer",
                          color: " #f0c26a",
                        }}
                        onClick={() =>
                          setShowInfoPopup(missionDetail.badge_configration)
                        }
                      >
                        +{missionDetail.badge_configration.length - 1}
                      </span>
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {!hideEranedBadges && (
          <div className="col-12 col-md-3">
            <div className="bg-info-of-mission text-center w-100 d-flex justify-content-between align-items-center bg-info-of-mission-2 mt-2 transparent-background">
              <div className="title-mission d-flex">
                <img
                  src={rewards}
                  style={{
                    width: "20px",
                    height: "190x",
                  }}
                />
                <span
                  className="ms-1"
                  style={{
                    color: "white",
                  }}
                >
                  Badges Earned
                </span>
              </div>
              {missionBadges && missionBadges.length > 0 ? (
                <div className="info-of-mission-text">
                  <div className="d-flex align-items-center">
                    <Tooltip
                      title={
                        missionBadges[0].title +
                        " " +
                        missionBadges[0].no_of_badges +
                        "x"
                      }
                    >
                      <img
                        src={
                          s3baseUrl +
                          missionBadges[0]?.general_icon?.thumbnail_1
                        }
                        alt=""
                        style={{
                          width: "19px",
                          borderRadius: "2px",
                        }}
                        className="badge-image"
                      />
                    </Tooltip>
                    {missionBadges.length - 1 > 0 && (
                      <Tooltip title={"View More"}>
                        <span
                          className="ms-1"
                          style={{
                            cursor: "pointer",
                            color: " #f0c26a",
                          }}
                          onClick={() => setShowInfoPopup(missionBadges)}
                        >
                          +{missionBadges.length - 1}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </div>
              ) : (
                <span
                  className="ms-1"
                  style={{
                    cursor: "pointer",
                    color: "#959595",
                    fontSize: "14px",
                  }}
                >
                  0
                </span>
              )}
            </div>
          </div>
        )}
      </div>

      <GeneralModelBox
        open={Boolean(showInfoPopup.length > 0)}
        setOpen={setShowInfoPopup}
        className={"challenge-video-days-questions-modal-new"}
        componentToPassDown={
          <RewardsPopup
            handleCross={() => setShowInfoPopup([])}
            showInfoPopup={showInfoPopup}
          />
        }
      />
    </>
  );
};

export default MissionCardCompleteForReport;

import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Chip, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import moment from "moment";
import { _get_mission_members_list_v2 } from "src/DAL/Missions/Missions";
import {
  api_date_format,
  dd_date_format,
  show_proper_words,
} from "src/utils/constants";
import { makeStyles } from "@mui/styles";
import CustomAvatarColorCode from "src/components/CustomAvatarColorCode";
import FilteredChip from "src/components/GeneralComponents/FilteredChip";
import ActiveLastBreadcrumb from "src/components/BreadCrums";
import MissionQuestFilter from "./MissionQuestFilter";
import { htmlDecode } from "src/utils/convertHtml";

let EMPTY_FILTER = {
  search_text: "",
  coins_from: 0,
  badge_type: "",
  coins_to: 0,
  from_start_date: null,
  to_start_date: null,
  from_end_date: null,
  to_end_date: null,
  mission_status: "all",
  page: 0,
  rowsPerPage: 50,
  coins_range: false,
  start_date_range: false,
  end_date_range: false,
  badge_levels: [],
};

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MissionMembers() {
  const classes = useStyles();
  const { mission_id } = useParams();
  const location = useLocation();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(0 + 1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [missionResult, setMissionResult] = useState({});
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [formDataUpdated, setformDataUpdated] = useState({});
  const mission_type = new URLSearchParams(location.search).get("type");

  const handleChangeBadgeLevels = (value) => {
    setFilterData((values) => ({ ...values, badge_levels: value }));
  };

  const handleChangeOthers = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeAll = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const moveToMemberProfile = (value) => {
    if (value.mission_status == "completed") {
      navigate(
        `/mission-levels/mission/mission-detail/${value?.user_info?._id}/${value?.mission_info?._id}`,
        {
          state: {
            ...value,
            move_from_page_title: `${mission_type} (${value?.mission_info?.title}) / ${mission_type} Members`,
            move_from_page_path: `mission-levels/missions-members/${value?.mission_info?._id}?type=${mission_type}`,
          },
        }
      );
    } else {
      navigate(
        `/mission-levels/inprogress/mission/mission-detail/${value?.user_info?._id}/${value?.mission_info?._id}`,
        {
          state: {
            ...value,
            move_from_page_title: `${mission_type} (${value?.mission_info?.title}) / ${mission_type} Members`,
            move_from_page_path: `mission-levels/missions-members/${value?.mission_info?._id}?type=${mission_type}`,
          },
        }
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handle_chips_data = (flterData) => {
    let chipData = { ...flterData };

    console.log("chipData   ___handle_chips_data", chipData);
    if (chipData?.start_date_range && chipData?.from_start_date) {
      let date = `Start Date From : ${dd_date_format(
        chipData.from_start_date
      )} - Start Date To :  ${dd_date_format(chipData.to_start_date)}`;
      chipData.date = {
        chip_label: date,
        chip_value: date,
      };
    }
    if (!chipData.badge_type) {
      delete chipData.badge_levels;
    }
    if (!chipData?.badge_levels || !chipData?.badge_levels?.length > 0) {
      delete chipData.badge_type;
    }
    if (chipData?.end_date_range && chipData?.from_end_date) {
      let date = `End Date From : ${dd_date_format(
        chipData.from_end_date
      )} - End Date To :  ${dd_date_format(chipData.to_end_date)}`;
      chipData.date_end = {
        chip_label: date,
        chip_value: date,
      };
    }

    if (chipData?.coins_range && chipData?.coins_to) {
      let coins = `Start Coins : ${chipData.coins_from} - End Coins :  ${chipData.coins_to}`;
      chipData.coins = {
        chip_label: coins,
        chip_value: coins,
      };
    }

    if (!chipData?.start_date_range) {
      delete chipData.date;
    }

    if (!chipData?.end_date_range) {
      delete chipData.date_end;
    }

    if (!chipData?.coins_range) {
      delete chipData.coins;
    }

    setFilterData({ ...chipData });

    if (chipData?.mission_status == "" || chipData?.mission_status == "all") {
      delete chipData.mission_status;
    }

    delete chipData.start_date_range;
    delete chipData.from_start_date;
    delete chipData.to_start_date;
    delete chipData.end_date_range;
    delete chipData.from_end_date;
    delete chipData.to_end_date;
    delete chipData.coins_from;
    delete chipData.coins_to;
    delete chipData.search_text;
    delete chipData.coins_range;
    delete chipData.page;
    delete chipData.rowsPerPage;

    setformDataUpdated(chipData);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    data.search_text = searchText;
    if (!data.date) {
      delete data.date;
      data.from_start_date = null;
      data.start_date_range = false;
      data.to_start_date = null;
    }
    if (!data.date_end) {
      delete data.date_end;
      data.from_end_date = null;
      data.end_date_range = false;
      data.to_end_date = null;
    }
    if (!data.coins) {
      delete data.coins;
      data.coins_from = 0;
      data.coins_range = false;
      data.coins_to = 0;
    }

    get_mission_members(data);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const searchFunction = (e) => {
    if (e) {
      e.preventDefault();
    }

    filterData.search_text = searchText;
    let error = "";
    if (
      filterData?.start_date_range &&
      filterData.from_start_date &&
      filterData.to_start_date &&
      moment(filterData.from_start_date).isAfter(
        moment(filterData.to_start_date)
      )
    ) {
      error = "Start Date To must be greater than Start Date From";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    if (+filterData.coins_from > +filterData?.coins_to) {
      error = "Coins To must be greater than Coins From";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    if (mission_type == "quest") {
      if (
        filterData?.end_date_range &&
        filterData.from_end_date &&
        filterData?.to_end_date &&
        moment(filterData.from_end_date).isAfter(moment(filterData.to_end_date))
      ) {
        error = "End Date To must be greater than End Date From";
        enqueueSnackbar(error, { variant: "error" });
        return;
      }
    }

    handleCloseFilterDrawer();
    setPage(0);
    setPageCount(1);
    get_mission_members(filterData);
    localStorage.setItem("mission_members", JSON.stringify(filterData));
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    handleCloseFilterDrawer();
    setPage(0);
    setPageCount(1);
    setSearchText("");
    setFilterData(EMPTY_FILTER);
    get_mission_members(EMPTY_FILTER);
    localStorage.removeItem("mission_members");
  };

  const get_mission_members = async (_filter_data) => {
    setIsLoading(true);
    let data = _filter_data;

    let postData = {
      search_text: data?.search_text,
      mission_status: data?.mission_status,
    };

    if (data?.start_date_range) {
      postData.from_start_date = api_date_format(data?.from_start_date);
      postData.to_start_date = api_date_format(data?.to_start_date);
    }

    if (data?.coins_range) {
      postData.coins_from = +data?.coins_from;
      postData.coins_to = +data?.coins_to;
    }

    if (data?.mission_status == "" || data?.mission_status == "all") {
      delete postData.mission_status;
    }

    if (
      !data?.start_date_range ||
      !data?.from_start_date ||
      !data?.to_start_date
    ) {
      delete postData.from_start_date;
      delete postData.to_start_date;
    }
    if (data.badge_type) {
      postData.badge_type = data.badge_type;
      if (data?.badge_levels?.length > 0) {
        postData.badge_levels = data?.badge_levels.map((level) => {
          return level._id;
        });
      }
    }

    if (mission_type == "quest") {
      if (data?.end_date_range) {
        postData.from_end_date = api_date_format(data?.from_end_date);
        postData.to_end_date = api_date_format(data?.to_end_date);
      }

      if (!data?.end_date_range || !data?.from_end_date || !data?.to_end_date) {
        delete postData.from_end_date;
        delete postData.to_end_date;
      }
    }

    const result = await _get_mission_members_list_v2(
      mission_id,
      page,
      rowsPerPage,
      postData
    );
    if (result.code === 200) {
      setMissionResult(result);
      console.log("result __result", result);
      const users = result.users_list.map((user) => {
        let { first_name, last_name, email, profile_image } = user.user_info;
        let user_name = `${first_name} ${last_name} (${email})`;
        let start_date = dd_date_format(user.mission_start_date);
        let end_date = user?.mission_end_date
          ? dd_date_format(user?.mission_end_date)
          : "N/A";
        return {
          ...user,
          user_name,
          start_date,
          end_date,
          table_avatar: {
            src: s3baseUrl + profile_image,
            alt: user_name,
          },
        };
      });
      handle_chips_data(data);
      setUserList(users);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_count);
      setIsLoading(false);
      localStorage.setItem(
        "mission_members",
        JSON.stringify({ ...data, page: page, rowsPerPage: rowsPerPage })
      );
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handle_mission_status = (row) => {
    return (
      <Chip
        width="150px"
        label={show_proper_words(row.mission_status)}
        variant="contained"
        className="mission-status-chip"
        color={row.mission_status === "completed" ? "success" : "warning"}
        size="small"
      />
    );
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Profile",
      renderData: (row) => {
        return (
          <p className="mb-0" style={{ cursor: "pointer" }}>
            <CustomAvatarColorCode
              alt={row?.user_info?.first_name}
              src={
                row?.user_info?.profile_image
                  ? s3baseUrl + row?.user_info?.profile_image
                  : "/"
              }
              colorCode={row?.current_badge_level?.color_code}
            />
          </p>
        );
      },
    },
    {
      id: "user_name",
      label: "User Name",
      renderData: (row) => {
        return (
          <p className="mb-0">{row?.user_name ? row?.user_name : "N/A"}</p>
        );
      },
    },
    {
      id: "email",
      label: "User Current Badge Level",
      renderData: (row) => {
        return (
          <div className="d-flex align-items-center">
            <span onClick={() => handleEdit(row)} className="pointer mr-2">
              {htmlDecode(row?.current_badge_level?.title)}
            </span>

            <img
              className="avatar-adjust ms-2"
              style={{ width: 20, height: 20 }}
              alt={row?.current_badge_level?.title}
              src={s3baseUrl + row?.current_badge_level?.icon?.thumbnail_1}
            />
          </div>
        );
      },
    },
    {
      id: "email",
      label: `${
        mission_type === "quest" ? "Quest" : "Mission"
      } Start Time User Badge Level `,
      renderData: (row) => {
        return (
          <div className="d-flex align-items-center">
            <span onClick={() => handleEdit(row)} className="pointer mr-2">
              {htmlDecode(row?.accept_time_badge_details?.title)}
            </span>

            <img
              className="avatar-adjust ms-2"
              style={{ width: 20, height: 20 }}
              alt={row?.accept_time_badge_details?.title}
              src={
                s3baseUrl + row?.accept_time_badge_details?.icon?.thumbnail_1
              }
            />
          </div>
        );
      },
    },
    {
      id: "start_date",
      label: "Start Date",
    },
    mission_type === "quest"
      ? {
          id: "end_date",
          label: "End Date",
        }
      : {
          id: "completed_mission_days",
          label: "Completed Days",
          renderData: (row) => <>{row?.completed_mission_days ?? 0}</>,
        },
    {
      id: "attracted_coins",
      label: "Coins Attracted",
    },
    {
      id: "target_coins",
      label: "Target Coins",
    },
    {
      id: "mission_status",
      label: `${show_proper_words(mission_type)} Status`,
      renderData: (row) => handle_mission_status(row),
    },
  ];

  let breadCrumbMenu = [
    {
      title: `Mission Levels`,
      navigation: `/mission-levels`,
      active: false,
    },
    {
      title: `${missionResult?.badge_level?.tagline} - ${show_proper_words(
        mission_type
      )} (${missionResult.mission?.title})`,
      navigation: `/mission-levels/${missionResult?.mission?.membership_level_info?.badge_level_info?._id}`,
      active: false,
    },
    {
      title: `${show_proper_words(mission_type)} Members`,
      navigation: ``,
      active: true,
    },
  ];
  const handleEdit = (value) => {
    navigate(
      `/mission/missions-members/update/${mission_id}/${value?.user_info?._id}`,
      {
        state: value,
      }
    );
  };

  const MENU_OPTIONS = [
    {
      label: "View Report",
      icon: "akar-icons:edit",
      handleClick: moveToMemberProfile,
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem(`mission_members`);
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      setFilterData(filter_data);
      setSearchText(filter_data?.search_text);
    }
    get_mission_members(filter_data);
  }, [page, rowsPerPage]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12 mb-4">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>

          <div className="col-lg-6">
            <h2>{show_proper_words(mission_type)} Members</h2>
          </div>

          <div className="col-lg-6 d-flex justify-content-end text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-1"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        <FilteredChip
          data={formDataUpdated}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          data={userList}
          className="card-with-background"
          pagePagination={true}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      </div>

      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle={`${show_proper_words(mission_type)} Filter`}
        componentToPassDown={
          <MissionQuestFilter
            filterData={filterData}
            handleChangeAll={handleChangeAll}
            handleChangeOthers={handleChangeOthers}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
            handleChangeBadgeLevels={handleChangeBadgeLevels}
          />
        }
      />
    </>
  );
}

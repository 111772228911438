import React from "react";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import { useContentSetting } from "src/Hooks/ContentSettingState";

export default function CommentMenuOptions(props) {
  const {
    comment,
    list_type,
    childComponent,
    handleReplyEdit,
    handleCommentEdit,
    handleCommentDelete,
    handleChildCommentDelete,
  } = props;
  const { isAccess, userInfo } = useContentSetting();
  const { handleAddChat } = useChat();

  const handleSendMessage = async () => {
    handleAddChat(comment.user_info_action_for?.action_id);
  };

  const isChatAllow = () => {
    if (
      isAccess.is_chat_allowed &&
      comment.user_info_action_for.action_id !== userInfo._id &&
      comment.user_info_action_for.action_by == "member_user"
    ) {
      return true;
    }
    return false;
  };

  const MENU_OPTIONS = [];
  let allowed_action = isAccess.edit_delete_option_in_source_all_source_feeds;
  if (
    comment?.comment_is_self ||
    (list_type !== "the_cosmos" && allowed_action)
  ) {
    MENU_OPTIONS.push({
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: childComponent
        ? handleChildCommentDelete
        : handleCommentDelete,
    });
    if (list_type !== "review") {
      MENU_OPTIONS.unshift({
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: childComponent ? handleReplyEdit : handleCommentEdit,
      });
    }
  }

  if (isChatAllow()) {
    MENU_OPTIONS.push({
      label: "Message",
      icon: "material-symbols:send",
      handleClick: handleSendMessage,
    });
  }

  return (
    <>
      {MENU_OPTIONS.length > 0 && (
        <div className="affirmation-dots single-comment-card-dots">
          <CustomPopover menu={MENU_OPTIONS} data={comment} />
        </div>
      )}
    </>
  );
}

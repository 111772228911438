import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Drawer } from "@mui/material";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
import { _get_Consultant_Nav_from_localStorage } from "src/DAL/localstorage/LocalStorage";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { projectMode } from "src/config/config";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------
DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { consultantInfo, consultantDetail } = useContentSetting();

  let default_paths = [
    "/dashboard",
    "/zoom-setting",
    "/edit-profile",
    "/welcome-reminder-setting",
    "/change-password",
    "/accountability-tracker-report",
    "/quarter-questions",
  ];

  var foundrout = consultantDetail?.nav_items.find(function (element) {
    if (element.option_value == "welcome_reminder_settings") {
      return true;
    }
  });

  if (foundrout) {
    default_paths.push("/welcome-reminder-setting");
  }

  const checkIfPathExists = (navItems, currentPath) => {
    for (const path of default_paths) {
      if (currentPath.includes(path)) {
        return true;
      }
    }
    for (const navItem of navItems) {
      if (navItem.path && currentPath.includes(navItem.path)) {
        return true;
      }
      if (navItem.matches && navItem.matches.includes(currentPath)) {
        return true;
      }
      if (navItem.child_options) {
        for (const childOption of navItem.child_options) {
          if (
            childOption.path &&
            (currentPath.includes(childOption.path) ||
              childOption.path.includes(currentPath))
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  const handlePathname = () => {
    if (pathname) {
      const pathParts = pathname.split("/");
      if (pathParts.length > 2) {
        return pathParts.slice(0, 2).join("/");
      } else {
        return pathname;
      }
    }
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  useEffect(() => {
    const isPathInNavItems = checkIfPathExists(
      consultantDetail?.nav_items,
      handlePathname()
    );
    if (!isPathInNavItems) {
      navigate("/dashboard");
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{
            display: "inline-flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Logo consultantInfo={consultantInfo?.brand_logo} />
        </Box>
      </Box>

      <NavSection
        navConfig={consultantDetail?.nav_items?.filter(
          (item) => item.value !== "welcome_reminder_settings"
        )}
      />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );
  let settings_routes = [
    "/welcome-reminder-setting",
    "/zoom-setting",
    "/live-events/",
    "/my-events/",
    "/leads-center",
    "/quest/",
  ];

  let isMatchRoute = settings_routes.some((route) => pathname.includes(route));
  if (isMatchRoute) {
    return (
      <div>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </div>
    );
  }

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

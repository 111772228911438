import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import Community from "../Community/Community";
import { mission_detail_api } from "src/DAL/Missions/Missions";
import ActiveLastBreadcrumb from "src/components/BreadCrums";
import QuestDashboard from "src/components/MissionLevels/Quest/QuestDashboard";
import QuestOverview from "src/components/MissionLevels/Quest/QuestOverview";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MissionQuestDetail() {
  const { mission_id, level_id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingfirst, setIsLoadingfirst] = useState(true);
  const [missionDetail, setMissionDetail] = useState({});
  let value = Number(localStorage.getItem("tabValuesMission"));
  const [tabValue, setTabValue] = useState(value || 0);
  const [missionBadges, setMissionBadges] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem("tabValuesMission", newValue);
  };

  const get_mission_detail = async () => {
    const result = await mission_detail_api(mission_id);
    if (result.code == 200) {
      setMissionDetail(result.mission);
      setMissionBadges(result.badge_configration);
      const selected_schedule = result.mission.mission_schedules.find(
        (item) => item._id === result.mission.show_day_on_dashboard
      );
      if (selected_schedule) {
        setSelectedSchedule(selected_schedule);
      }
      setIsLoading(false);
      setIsLoadingfirst(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingfirst(false);
    }
  };

  const handleClick = (schedule) => {
    navigate(
      `/mission-levels/${level_id}/quest/${mission_id}/schedule/${schedule._id}`,
      {
        state: schedule,
      }
    );
  };

  const TABS_OPTIONS = [
    {
      title: "Dashboard",
      component: (
        <QuestDashboard
          selectedSchedule={selectedSchedule}
          missionDetail={missionDetail}
          handleClick={handleClick}
        />
      ),
    },
    {
      title: "Quest Overview",
      component: (
        <QuestOverview
          missionBadges={missionBadges}
          missionDetail={missionDetail}
          handleClick={handleClick}
        />
      ),
    },
    {
      title: "Community",
      component: (
        <>
          {isLoading ? (
            <CircularProgress className={classes.loading} color="primary" />
          ) : (
            <div className="mt-3">
              <Community
                list_type="mission"
                event_id={mission_id}
                inner_type="quest"
                onClick={() => setTabValue(0)}
              />
            </div>
          )}
        </>
      ),
    },
  ];

  let breadCrumbMenu = [
    {
      title: "Mission Levels",
      navigation: `/mission-levels`,
    },
    {
      title:
        missionDetail?.membership_level_info?.badge_level_info?.tagline ||
        "Levels",
      navigation: `/mission-levels/${level_id}`,
    },
    {
      title: missionDetail.title,
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    setIsLoadingfirst(true);
    get_mission_detail();
  }, [mission_id]);

  if (isLoadingfirst) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="mission-level-heading">
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
        <div className="row mobile-margin">
          <div
            className="col-12 mt-2 d-block  level-icons position-relative "
            style={{ zIndex: "999" }}
          >
            <MUICustomTabs
              data={TABS_OPTIONS}
              value={tabValue}
              handleChange={handleChange}
              className="challenge-video-detail-tabs"
            />
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Avatar,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScalingAnswerMission from "./ScalingAnswerMission";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import PieMissionChart from "./PieMissionChart";
import { labels } from "src/utils/constants";
import GroupedChart from "./GroupedChart";
import { ReactVideoPlayer } from "src/components";
import { s3baseUrl } from "src/config/config";
import ComparisonChart from "./ComparisonChart";
import MissionCardCompleteForReport from "./MissionCardCompleteForReport";
import LockIcon from "@mui/icons-material/Lock";

const MissionReportNew = ({
  reportData,
  mcqData,
  graphStructure,
  onScreenQuestion,
  missionDetail,
  missionBadges,
  response,
  type,
}) => {
  const [expanded, setExpanded] = useState(null); // State to track which accordion is open
  const handleChange = (panel) => (event, isExpanded) => {
    if (!response.permission_to_view_content && panel !== reportData.length - 1)
      return;
    if (!response.permission_to_view_content && type == "in_progress") return;
    setExpanded((prev) => (prev === panel ? null : panel));
  };
  useEffect(() => {
    if (type == "in_progress") {
      if (!response.permission_to_view_content) {
        setExpanded(null);
      } else {
        if (reportData && reportData.length > 0) {
          setExpanded(reportData.length - 1);
        }
      }
    } else {
      if (reportData && reportData.length > 0) {
        setExpanded(reportData.length - 1);
      }
    }
  }, [reportData]);
  return (
    <>
      <div className="container">
        <h4
          className="mt-2 mb-0"
          style={{ color: "#ffd889", fontSize: "20px" }}
        >
          Mission Report Graph Overview
        </h4>
        <div className="row mt-2 mb-2">
          <div className="col-12">
            <MissionCardCompleteForReport
              missionDetail={missionDetail}
              missionBadges={missionBadges}
              // missionDashboaerd={missionDashboaerd}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {response.permission_to_view_content && (
            <>
              {graphStructure && graphStructure.length > 0 && (
                <div className="col-lg-12">
                  <div className="card p-2">
                    <GroupedChart graphStructure={graphStructure} />
                  </div>
                </div>
              )}
              {onScreenQuestion && onScreenQuestion.length > 0 && (
                <div className="col-12 mt-2">
                  <ComparisonChart onScreenQuestion={onScreenQuestion} />
                </div>
              )}
              {mcqData &&
                mcqData.length > 0 &&
                mcqData.map((item, index) => {
                  return (
                    <>
                      {" "}
                      <div
                        className={`${
                          index === mcqData.length - 1 &&
                          mcqData.length % 2 !== 0
                            ? "col-lg-12"
                            : mcqData.length > 1
                            ? "col-lg-6"
                            : "col-lg-12"
                        } mt-2 ${
                          index === mcqData.length - 1 &&
                          mcqData.length % 2 !== 0
                            ? "pe-2"
                            : index % 2 === 0
                            ? "pe-0"
                            : "pe-2"
                        }`}
                      >
                        <div className="card h-100 pt-3">
                          <Tooltip title={item.question_statement}>
                            <div className="d-flex align-items-baseline ms-2 me-2">
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  color: "#ffd889",
                                }}
                              >
                                Q .
                              </span>
                              <h4
                                class="ps-1"
                                style={{ fontSize: "14px", fontWeight: "200" }}
                              >
                                {
                                  <div
                                    className="color-white"
                                    dangerouslySetInnerHTML={{
                                      __html: item.question_statement,
                                    }}
                                  ></div>
                                }
                              </h4>
                            </div>
                          </Tooltip>
                          <PieMissionChart item={item} />
                        </div>
                      </div>
                    </>
                  );
                })}

              {reportData && reportData.length > 0 && (
                <h4
                  className="mt-5 mb-0 "
                  style={{ color: "#ffd889", fontSize: "20px" }}
                >
                  Mission Report Detail Overview
                </h4>
              )}
            </>
          )}
          {!response.permission_to_view_content &&
            reportData &&
            reportData.length > 0 && (
              <div className="col-12">
                <p class="unlock-date-note">
                  Note: This member has not enabled content viewing for this
                  mission, so the content is currently not visible to you. Once
                  the member grants access, you will be able to view the
                  content..
                </p>
              </div>
            )}
          {reportData && reportData.length > 0
            ? reportData.map((item, index) => {
                let gratitude_daily_array = [];
                let daily_dynamite_array = [];
                let find_growth_array = null;
                if (item.allowed_actions.length > 0) {
                  find_growth_array = item.allowed_actions.find(
                    (action) => action.type === "dynamite_dairy"
                  );
                }

                if (find_growth_array) {
                  gratitude_daily_array = find_growth_array.gratitude_options;
                  daily_dynamite_array = find_growth_array.options;
                } else {
                  gratitude_daily_array = [
                    {
                      option: "",
                    },
                    {
                      option: "",
                    },
                    {
                      option: "",
                    },
                  ];
                  daily_dynamite_array = [
                    {
                      option: "",
                    },
                    {
                      option: "",
                    },
                    {
                      option: "",
                    },
                  ];
                }

                return (
                  <div className="col-12 mt-2" key={index}>
                    <Accordion
                      expanded={expanded === index}
                      onChange={handleChange(index)}
                    >
                      <AccordionSummary
                        expandIcon={
                          (response.permission_to_view_content ||
                            reportData.length - 1 == index) &&
                          type !== "in_progress" ? (
                            <ExpandMoreIcon />
                          ) : (
                            <LockIcon />
                          )
                        }
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                        className="svg-color"
                      >
                        <div className="d-flex justify-content-between w-100">
                          <h2
                            style={{
                              marginBottom: 0,
                              fontSize: 15,
                              color: "white",
                            }}
                          >
                            {item.title}
                          </h2>

                          <span
                            style={{
                              color: "#c8c8c8",
                              marginRight: "10px",
                            }}
                          >
                            {/* {reply.date} */}
                          </span>
                        </div>
                      </AccordionSummary>

                      {item.general_allowed_actions &&
                        item.general_allowed_actions?.length > 0 && (
                          <div className="col-12 position-relative">
                            <form className={` allowed-actions-box pb-0`}>
                              <h2
                                style={{
                                  marginBottom: "6px",
                                  fontSize: "18px",
                                }}
                                className=""
                              >
                                {
                                  item.content_settings
                                    ?.action_statement_heading
                                }
                              </h2>

                              {item.general_allowed_actions.map(
                                (items, index) => {
                                  let { _id, action_statement, is_required } =
                                    items;

                                  let find_answer = null;
                                  if (
                                    item.allowed_actions &&
                                    item.allowed_actions?.length > 0
                                  ) {
                                    find_answer = item.allowed_actions.find(
                                      (i) => i._id == _id
                                    );
                                  }

                                  return (
                                    <>
                                      <div className="action-main-container mb-2">
                                        <div
                                          className={`mt-2 action-checkbox-box`}
                                        >
                                          <div
                                            className={`action-checkbox ${
                                              find_answer ? "checked" : ""
                                            }`}
                                          >
                                            {find_answer && (
                                              <i class="fa-solid fa-check"></i>
                                            )}
                                          </div>
                                          <div className="checkbox-label">
                                            {action_statement}
                                          </div>
                                          <span className="required-asterisk ms-1">
                                            {is_required && "*"}
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </form>
                          </div>
                        )}
                      <div className="row">
                        {item.growth_tool_allowed_actions &&
                          item.growth_tool_allowed_actions?.length > 0 && (
                            <>
                              {item.growth_tool_allowed_actions.map(
                                (action) => {
                                  let { is_required } = action;

                                  return (
                                    <div
                                      className={`position-relative  ${"col-lg-6"} pe-0`}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <form
                                        className={` allowed-actions-box pb-4 flex-grow-1 ps-3 mt-0`}
                                      >
                                        <h2
                                          style={{
                                            marginBottom: "14px",
                                            fontSize: "20px",
                                          }}
                                          className=""
                                        >
                                          {
                                            item?.content_settings
                                              ?.gratitude_action_title
                                          }
                                        </h2>

                                        <>
                                          <div className="action-main-container mb-2">
                                            {gratitude_daily_array.map(
                                              (items, index) => {
                                                const lable =
                                                  labels.gratitudeLabels[index];

                                                return (
                                                  <>
                                                    <div className="action-checkbox-box-description">
                                                      <span className="ms-2 custom-lable color-white">
                                                        {lable}
                                                      </span>
                                                      <span className="required-asterisk ms-1">
                                                        {is_required && "*"}
                                                      </span>
                                                      <TextareaAutosize
                                                        aria-label="empty textarea"
                                                        className={`text-color textarea-autosize`}
                                                        placeholder={index + 1}
                                                        value={items.option}
                                                        sx={{ color: "white" }}
                                                        minRows={1}
                                                        style={{
                                                          width: "100%",
                                                          borderRadius: "11px",
                                                          background:
                                                            "rgb(29, 37, 55)",
                                                          paddingTop: "10px",
                                                          paddingBottom: "10px",
                                                          paddingLeft: "15px",
                                                          color: "white",
                                                          fontSize: "15px",
                                                          border:
                                                            "2px solid rgba(71, 66, 34, 0.3);",
                                                        }}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                        </>
                                      </form>
                                    </div>
                                  );
                                }
                              )}
                            </>
                          )}
                        {item.growth_tool_allowed_actions &&
                          item.growth_tool_allowed_actions?.length > 0 && (
                            <>
                              {item.growth_tool_allowed_actions.map(
                                (action) => {
                                  let { is_required } = action;

                                  return (
                                    <div
                                      className={`position-relative  ${"col-lg-6"} pe-3 ps-0`}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <form
                                        className={` allowed-actions-box pb-4 flex-grow-1 ps-2 mt-0`}
                                      >
                                        <h2
                                          style={{
                                            marginBottom: "14px",
                                            fontSize: "20px",
                                          }}
                                          className=""
                                        >
                                          {
                                            item?.content_settings
                                              ?.dynamite_dairy_action_title
                                          }
                                        </h2>

                                        <>
                                          <div className="action-main-container mb-2">
                                            {daily_dynamite_array.map(
                                              (items, index) => {
                                                const lable =
                                                  labels.dailyDynamiteLabels[
                                                    index
                                                  ];

                                                return (
                                                  <>
                                                    <div className="action-checkbox-box-description">
                                                      <span className="ms-2 custom-lable color-white">
                                                        {lable}
                                                      </span>
                                                      <span className="required-asterisk ms-1">
                                                        {is_required && "*"}
                                                      </span>
                                                      <TextareaAutosize
                                                        aria-label="empty textarea"
                                                        className={`text-color textarea-autosize`}
                                                        placeholder={index + 1}
                                                        value={items.option}
                                                        sx={{ color: "white" }}
                                                        minRows={1}
                                                        style={{
                                                          width: "100%",
                                                          borderRadius: "11px",
                                                          background:
                                                            "rgb(29, 37, 55)",
                                                          paddingTop: "10px",
                                                          paddingBottom: "10px",
                                                          paddingLeft: "15px",
                                                          color: "white",
                                                          fontSize: "15px",
                                                          border:
                                                            "2px solid rgba(71, 66, 34, 0.3);",
                                                        }}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                        </>
                                      </form>
                                    </div>
                                  );
                                }
                              )}
                            </>
                          )}
                      </div>
                      {item?.questions?.questions &&
                        item?.questions?.questions?.length > 0 && (
                          <>
                            <h2
                              style={{ marginBottom: "6px", fontSize: "18px" }}
                              className="ms-3 mt-3"
                            >
                              {item?.content_settings
                                ?.onscreen_question_title ||
                                "Content Questions"}
                            </h2>
                            {item?.questions?.questions?.map(
                              (reply, replyIndex) => {
                                let find_answer =
                                  item?.questions?.question_replies.find(
                                    (answer) => answer._id == reply._id
                                  );

                                return (
                                  <div
                                    className="px-3 pb-2 pt-2 card mb-2 ms-2 me-2"
                                    style={{ background: "rgb(35 35 35)" }}
                                    key={replyIndex}
                                  >
                                    <div className="d-flex justify-content-between align-items-center">
                                      <Typography className="question-text">
                                        <div
                                          className="color-white"
                                          dangerouslySetInnerHTML={{
                                            __html: reply.question_statement,
                                          }}
                                        />
                                      </Typography>
                                    </div>

                                    {reply?.question_type === "mcq" && (
                                      <div className="mt-2">
                                        <FormControl style={{ width: "100%" }}>
                                          <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue=""
                                            name="radio-buttons-group"
                                            value={
                                              find_answer?.answer_statement
                                            }
                                          >
                                            {reply?.options.map(
                                              (option, optionIndex) => (
                                                <div
                                                  className={`question-answer  ${
                                                    find_answer?.answer_statement
                                                      ? option ===
                                                        find_answer?.answer_statement
                                                        ? "selectedAnswer"
                                                        : ""
                                                      : ""
                                                  }`}
                                                  key={option.id}
                                                >
                                                  <FormControlLabel
                                                    value={option}
                                                    id={`capsule${optionIndex}`}
                                                    className="answer"
                                                    style={{ color: "#fff" }}
                                                    control={<Radio />}
                                                    label={option}
                                                  />
                                                </div>
                                              )
                                            )}
                                          </RadioGroup>
                                        </FormControl>
                                      </div>
                                    )}

                                    {reply.question_type === "scaling" && (
                                      <div className="mt-2">
                                        <FormControl>
                                          <ul className="list-unstyled pt-1 mb-0">
                                            <li className="row">
                                              <div className="col-12">
                                                <ScalingAnswerMission
                                                  question={reply}
                                                  find_answer={find_answer}
                                                />
                                              </div>
                                            </li>
                                          </ul>
                                        </FormControl>
                                      </div>
                                    )}
                                    {reply.question_type === "checkbox" && (
                                      <div className="mt-2">
                                        <FormControl style={{ width: "100%" }}>
                                          <FormGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue=""
                                            name="radio-buttons-group"
                                            value={reply?.answer}
                                          >
                                            {reply?.options.map(
                                              (option, index) => {
                                                let checkbox_answer =
                                                  find_answer?.answer_statement?.find(
                                                    (old_answer) =>
                                                      old_answer == option
                                                  );
                                                return (
                                                  <div
                                                    className={`question-answer`}
                                                    key={option.id}
                                                  >
                                                    <FormControlLabel
                                                      value={option}
                                                      id={`capsule${index}`}
                                                      className="answer"
                                                      control={
                                                        <Checkbox
                                                          checked={
                                                            checkbox_answer
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                      }
                                                      label={option}
                                                    />
                                                  </div>
                                                );
                                              }
                                            )}
                                          </FormGroup>
                                        </FormControl>
                                      </div>
                                    )}

                                    {reply.question_type === "textarea" && (
                                      <div className="mt-2">
                                        <FormControl style={{ width: "100%" }}>
                                          <TextField
                                            id="outlined-multiline-static"
                                            multiline
                                            rows={4}
                                            className="mb-3"
                                            value={
                                              find_answer?.answer_statement
                                            }
                                            variant="outlined"
                                            disabled
                                          />
                                        </FormControl>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </>
                        )}
                      {item?.questions?.after_action_questions &&
                        item?.questions?.after_action_questions.length > 0 && (
                          <>
                            <h2
                              style={{ marginBottom: "6px", fontSize: "18px" }}
                              className="ms-3 mt-3"
                            >
                              Interactive Learining Experience
                            </h2>
                            {item?.questions?.after_action_questions?.map(
                              (reply, replyIndex) => {
                                let find_answer =
                                  item?.questions?.question_replies.find(
                                    (answer) => answer._id == reply._id
                                  );

                                return (
                                  <div
                                    className="px-3 pb-2 pt-2 card mb-2 ms-2 me-2"
                                    style={{ background: "rgb(35 35 35)" }}
                                    key={replyIndex}
                                  >
                                    <div className="d-flex justify-content-between align-items-center">
                                      <Typography className="question-text">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: reply.question_statement,
                                          }}
                                        />
                                      </Typography>
                                    </div>

                                    {reply?.question_type === "mcq" && (
                                      <div className="mt-2">
                                        <FormControl style={{ width: "100%" }}>
                                          <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue=""
                                            name="radio-buttons-group"
                                            value={
                                              find_answer?.answer_statement
                                            }
                                          >
                                            {reply?.options.map(
                                              (option, optionIndex) => (
                                                <div
                                                  className={`question-answer  ${
                                                    find_answer?.answer_statement
                                                      ? option ===
                                                        find_answer?.answer_statement
                                                        ? "selectedAnswer"
                                                        : ""
                                                      : ""
                                                  }`}
                                                  key={option.id}
                                                >
                                                  <FormControlLabel
                                                    value={option}
                                                    id={`capsule${optionIndex}`}
                                                    className="answer"
                                                    style={{ color: "#fff" }}
                                                    control={<Radio />}
                                                    label={option}
                                                  />
                                                </div>
                                              )
                                            )}
                                          </RadioGroup>
                                        </FormControl>
                                      </div>
                                    )}

                                    {reply.question_type === "scaling" && (
                                      <div className="mt-2">
                                        <FormControl>
                                          <ul className="list-unstyled pt-1 mb-0">
                                            <li className="row">
                                              <div className="col-12">
                                                <ScalingAnswerMission
                                                  question={reply}
                                                  find_answer={find_answer}
                                                />
                                              </div>
                                            </li>
                                          </ul>
                                        </FormControl>
                                      </div>
                                    )}
                                    {reply.question_type === "checkbox" && (
                                      <div className="mt-2">
                                        <FormControl style={{ width: "100%" }}>
                                          <FormGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue=""
                                            name="radio-buttons-group"
                                            value={reply?.answer}
                                          >
                                            {reply?.options.map(
                                              (option, index) => {
                                                let checkbox_answer =
                                                  find_answer?.answer_statement?.find(
                                                    (old_answer) =>
                                                      old_answer == option
                                                  );
                                                return (
                                                  <div
                                                    className={`question-answer`}
                                                    key={option.id}
                                                  >
                                                    <FormControlLabel
                                                      value={option}
                                                      id={`capsule${index}`}
                                                      className="answer"
                                                      control={
                                                        <Checkbox
                                                          checked={
                                                            checkbox_answer
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                      }
                                                      label={option}
                                                    />
                                                  </div>
                                                );
                                              }
                                            )}
                                          </FormGroup>
                                        </FormControl>
                                      </div>
                                    )}

                                    {reply.question_type === "textarea" && (
                                      <div className="mt-2">
                                        <FormControl style={{ width: "100%" }}>
                                          <TextField
                                            id="outlined-multiline-static"
                                            multiline
                                            rows={4}
                                            className="mb-3"
                                            value={
                                              find_answer?.answer_statement
                                            }
                                            variant="outlined"
                                            disabled
                                          />
                                        </FormControl>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </>
                        )}
                      {item?.content_settings?.is_show_general_note && (
                        <div className="action-checkbox-box-description  ps-3 pe-3 pb-2">
                          <h4 className="ms-2" style={{ color: "#ffd889" }}>
                            {item?.content_settings?.general_note_title ||
                              "Journal"}
                          </h4>
                          <TextareaAutosize
                            aria-label="empty textarea"
                            className={`text-color textarea-autosize `}
                            name="statement"
                            value={item?.general_note}
                            placeholder={labels.journal}
                            disabled={true}
                            sx={{ color: "white" }}
                            minRows={4}
                            style={{
                              width: "100%",
                              borderRadius: "11px",
                              background: "rgb(29, 37, 55)",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              paddingLeft: "15px",
                              color: "white",
                              fontSize: "15px",
                              border: "2px solid rgba(71, 66, 34, 0.3);",
                            }}
                          />
                        </div>
                      )}
                    </Accordion>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
};

export default MissionReportNew;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import {
  DeleteSubscriberMemberApi,
  memberSubscriberListingApi,
} from "src/DAL/member/Member";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";

const TABLE_HEAD = [
  // { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "page_name", label: "Page Title", alignRight: false },
  { id: "plan_title", label: "Plan Title", alignRight: false },
  { id: "affiliate_name", label: "Referral User", alignRight: false },
  {
    id: "created_at",
    label: "Subscribe Date",
    alignRight: false,
  },
  {
    id: "preview_link_data",
    label: "Agreement PDF",
    alignRight: false,
    type: "link",
  },

  {
    id: "register_url",
    label: "Register Link",
    alignRight: false,
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MemberSubscribersList() {
  const navigate = useNavigate();
  const { member_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [memberData, setMemberData] = useState({});
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [pageCount, setPageCount] = useState(1);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const getMembersList = async () => {
    setIsLoading(true);
    const result = await memberSubscriberListingApi(
      member_id,
      page,
      rowsPerPage,
      searchText
    );
    if (result.code == 200) {
      const members = result.event_subscriber?.map((event, index) => {
        let event_plan = event.plan_info;
        let plan_title = "N/A";
        let created_at = "N/A";
        let title = "";
        if (event?.page_info && event?.page_info?.sale_page_title) {
          title = event?.page_info?.sale_page_title;
        } else if (event?.module_info && event?.module_info?.title) {
          title = event?.module_info?.title;
        }

        let affiliate_name = "N/A";
        if (event_plan?.plan_title) {
          plan_title = event_plan?.plan_title;
        }
        let payment_access = event_plan?.payment_access;
        if (plan_title != "N/A") {
          if (event_plan?.is_plan_free) {
            plan_title = plan_title + " (Free)";
          } else if (payment_access == "recursion") {
            plan_title = plan_title + " (Recurring)";
          } else {
            plan_title = plan_title + " (OneTime)";
          }
        }
        let affiliate = event.affiliate_info?.affiliate_user_info;
        if (affiliate && affiliate.first_name) {
          affiliate_name = affiliate.first_name + " " + affiliate.last_name;
        }

        if (event.createdAt) {
          created_at = moment.utc(event.createdAt).format("DD-MM-YYYY");
        }

        return {
          ...event,
          page_name: title,
          created_at,
          plan_title: plan_title,
          affiliate_name: affiliate_name,
          preview_link_data: {
            to: s3baseUrl + event.aggrement_pdf_url, // yaha pr check lgaana ha agr page landing ha to slug ni attach krna
            target: "_blank",
            show_text: event.aggrement_pdf_url ? "Preview" : "",
            className: "anchor-style",
          },
        };
      });
      setMemberData(result.member);
      setSubscriptionsData(members);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMembersList();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (value) => {
    navigate(`/member/edit-member-subscribers/${value._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteSubscriberMemberApi(deleteDoc._id);
    if (result.code === 200) {
      setSubscriptionsData((prev) => {
        return prev.filter((data) => data._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    getMembersList();
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this subscription?"}
        handleAgree={handleDelete}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <p className="pt-2 ms-2">
              {memberData?.first_name +
                " " +
                memberData?.last_name +
                " (" +
                memberData?.email +
                ") "}
            </p>
          </div>
          <div className="col-lg-8 col-sm-12 mb-3 mt-3">
            <h2>Member Subscriptions</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end"></div>
        </div>
        <div className="row">
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              // MENU_OPTIONS={MENU_OPTIONS}
              data={subscriptionsData}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}

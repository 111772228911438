import { Avatar } from "@mui/material";
import React from "react";

const CustomAvatarColorCode = ({ alt, src, colorCode, width, height }) => {
  return (
    <>
      <Avatar
        className="avatar-adjust m-0"
        sx={{
          width: width ?? 40,
          height: height ?? 40,
          border: `3px solid ${colorCode ?? "transparent"}`,
        }}
        alt={alt}
        src={src}
      />
    </>
  );
};

export default CustomAvatarColorCode;

import { Avatar } from "@mui/material";
import React, { useState } from "react";
import { medal_1, medal_2, medal_3 } from "src/assets";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { s3baseUrl } from "src/config/config";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import CoinsLeaderPopup from "./CoinsLeaderPopup";
import { get_kmb_number } from "src/utils/constants";
import CustomAvatarColorCode from "src/components/CustomAvatarColorCode";

export default function MissionLeaderboard({ users, type, inner_type }) {
  const [showAllPopUp, setShowAllPopUp] = useState(false);

  return (
    <div className="card p-3 members-leader-board mt-3 mt-lg-0">
      <div className="d-flex justify-content-between align-items-baseline">
        {inner_type === "quest" ? (
          <span className="challenge-heading unlock-color">Quest Members</span>
        ) : (
          <span className="challenge-heading unlock-color">{`Mission ${
            type === "coins_leaderboard" ? "In Progress" : "Completed"
          }`}</span>
        )}
      </div>

      <hr />
      {users.length > 0 ? (
        <>
          {users.map((item, index) => {
            return (
              <div
                className={`d-flex justify-content-between align-items-center mt-2 `}
                key={item._id}
              >
                <div className="d-flex w-100">
                  <div className="me-1">
                    {inner_type === "quest" ? (
                      <span className={`${index < 9 ? "me-2" : ""}`}>
                        {index + 1}.
                      </span>
                    ) : index <= 2 ? (
                      <Avatar
                        src={
                          index === 0
                            ? medal_1
                            : index === 1
                            ? medal_2
                            : medal_3
                        }
                        sx={{ width: 20, height: 20 }}
                      />
                    ) : (
                      <div>
                        <span className={`${index < 9 ? "me-2" : ""}`}>
                          {index + 1}.
                        </span>
                      </div>
                    )}
                  </div>

                  <CustomAvatarColorCode
                    alt={item.user_info?.name}
                    src={s3baseUrl + item.user_info?.profile_image}
                    colorCode={item?.badge_info?.color_code}
                    width={30}
                    height={30}
                  />
                  <div className="user-name ms-2 flex-grow-1">
                    <div>
                      {item.user_info?.first_name +
                        " " +
                        item.user_info?.last_name}
                    </div>
                    {type === "coins_leaderboard" && (
                      <div className="user-action-detail">
                        <div>{`${item.completed_days}/${item.mission_duration}`}</div>
                      </div>
                    )}
                  </div>
                  {inner_type !== "quest" && (
                    <div className="user-coins">
                      {get_kmb_number(item.mission_attracted_coins)}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {users.length == 10 && (
            <div className="text-center mt-2">
              <span
                className="challenge-heading unlock-color view-all-button"
                onClick={() => setShowAllPopUp(true)}
              >
                View All
              </span>
            </div>
          )}
        </>
      ) : (
        <>
          <RecordNotFound title="Data not found" show_new_image={true} />
        </>
      )}

      <GeneralModelBox
        open={Boolean(showAllPopUp)}
        setOpen={setShowAllPopUp}
        className={"challenge-video-days-questions-modal"}
        componentToPassDown={
          <CoinsLeaderPopup
            handleCross={() => setShowAllPopUp("")}
            type={type}
            inner_type={inner_type}
          />
        }
      />
    </div>
  );
}

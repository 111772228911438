import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import CircleIcon from "@mui/icons-material/Circle";

import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { get_root_value } from "src/utils/domUtils";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Pagination,
  Chip,
  Tooltip,
} from "@mui/material";
// components
// import Page from "../components/Page";
// import Label from "./Label";
// import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import Label from "src/components/Label";
import { UserListHead, UserListToolbar } from "src/components/_dashboard/user";
// import UserListHead from "./UserListHead";
// import GoalStatementMenu from "../components/_dashboard/user/GoalStatementMenu";
// import { QuestionListing } from "src/DAL/GoalStatement/GoalStatement";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";

import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";

// import {
//   consultantListing,
//   DeleteConsultantApi,
// } from "src/DAL/consultant/consultant";
import {
  transactionListApi,
  transactionListApiNew,
} from "../../DAL/TransactionList/transactionList";
import moment from "moment";
import { convertCurrencyToSign } from "src/utils/constants";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import TransactionFilterDrawer from "src/DAL/TransactionList/TransactionFilterDrawer";
import UserListToolbars from "src/components/_dashboard/user/UserListToolbars";
import { get_root_value } from "src/utils/domUtils";
import { projectMode, s3baseUrl } from "src/config/config";

//
// import USERLIST from "../_mocks_/user";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  //   { id: "name", label: " Name", alignRight: false },
  { id: "email", label: "Name", alignRight: false },
  { id: "amount", label: "Program Amount", alignRight: false },
  { id: "type", label: "Transaction", alignRight: false },
  { id: "type", label: "Commission Amount", alignRight: false },
  { id: "transaction_mode", label: "Transaction Mode", alignRight: false },
  {
    id: "agrement_pdf_url",
    label: "Aggrement PDF",
  },
  {
    id: "marketing_affliliate_url_name",
    label: "Marketing Affiliate ",
  },
  {
    id: "marketing_affiliate_comission",
    label: "Marketing Affiliate Commission ",
  },
  { id: "transactionDate", label: "Date", alignRight: false },
  // { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.transaction_type.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _user.email?.member?.email
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function PaymentTransactionsList() {
  const navigate = useNavigate();
  const params = useLocation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [affiliate, setAffiliate] = useState();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [member, setData] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [UserId, setUserId] = useState(false);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    title: projectMode == "DEV" ? "Sand Box" : "Live",
    value: projectMode == "DEV" ? "sandBox" : "live",
  });
  const [selectedValueUpdated, setSelectedValueUpdated] = useState({
    title: projectMode == "DEV" ? "Sand Box" : "Live",
    value: projectMode == "DEV" ? "sandBox" : "live",
  });
  const handleOpen = (e) => {
    setUserId(e);
    setOpen(true);
  };

  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };

  const getConsultantListing = async (value) => {
    setIsLoading(true);

    try {
      setIsLoading(true);
      const objectData = {
        search_text: filterName ? filterName : "",
        transaction_mode:
          value == "empty"
            ? "all"
            : selectedValue.value
            ? selectedValue.value
            : "all",
      };
      if (value == "empty") {
        setSelectedValue({
          title: "All",
          value: "all",
        });
      }
      const result = await transactionListApiNew(page, rowsPerPage, objectData);

      if (result.code == 200) {
        const data = result.transaction?.map((transaction, index) => {
          let marketing_affliliate_url_name;
          if (transaction?.marketing_affiliate?.affiliate_url_name) {
            marketing_affliliate_url_name =
              transaction.marketing_affiliate.affiliate_url_name;
          }
          let market_affiliate_name = "N/A";
          let market_affiliate_commission =
            convertCurrencyToSign(transaction.currency) + "0.00";
          let market_affiliate_info =
            transaction.marketing_affiliate?.affiliate_user_info;

          if (market_affiliate_info) {
            if (transaction.marketing_affiliate_comission) {
              market_affiliate_commission =
                convertCurrencyToSign(transaction.currency) +
                transaction.marketing_affiliate_comission.toFixed(2);
            }

            market_affiliate_name =
              market_affiliate_info.first_name +
              " " +
              market_affiliate_info.last_name;
          }

          return {
            ...transaction,
            id: transaction._id,
            amount: transaction?.amount,
            market_affiliate_name,
            market_affiliate_commission,
            Username: transaction,
            transaction_request_type: transaction?.transaction_request_type,
            transaction_type: transaction?.transaction_type,
            agrement_pdf_url: transaction?.agrement_pdf_url,
            transactionDate: transaction?.transaction_date
              ? moment(transaction?.transaction_date).format("DD-MM-YYYY")
              : "N/A",
            currency: transaction?.currency,
            status: transaction?.transaction_status,
            transaction_mode: transaction?.transaction_mode,
            commission_amount: transaction.referral_commission,
            count: index + 1 + rowsPerPage * page,
          };
        });

        setUserList(data);
        setData(result.transaction);
        setQuestionData(result.transaction);
        setAffiliate(result.affiliate);
        setTotalMembers(result.total_member_count);
        setTotalPages(result.total_pages);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleEdit = (value) => {
    navigate(`/consultant/edit-consultant/${value.id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteDoc.id;
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("transaction_page_number", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleSearchName = () => {
    getConsultantListing();
    setPage(0);
    setPageCount(1);
  };
  const handleNavigate = () => {
    navigate(`/consultant/add-consultant`);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  // functions for filter
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleDeleteProgramChips = (value) => {
    setPage(0);
    setPageCount(1);
    value.value = "all";
    value.title = "All";
    setSelectedValue(value);
    setSelectedValueUpdated(value);
    getConsultantListing("empty");
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handleClearFilterall = (value) => {
    setPage(0);
    setPageCount(1);
    value.value = "all";
    value.title = "All";
    setSelectedValue(value);
    setSelectedValueUpdated(value);
    getConsultantListing("empty");
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  useEffect(() => {
    getConsultantListing();
  }, [page, rowsPerPage]);
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="row">
            <div className="col-10">
              <h2>Transactions</h2>
            </div>
            <div className="col-2 mt-2  ps-0" style={{ textAlign: "end" }}>
              <button
                className="small-contained-button me-2 mt-1 mb-4 "
                onClick={handleOpenFilterDrawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
            </div>
          </div>

          <div className="col-lg-4 col-sm-12 text-end">
            {/* <button onClick={handleNavigate} className="small-contained-button">
              Add Consultant
            </button> */}
          </div>
        </div>
        {/* <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton> */}
        <div className="row mb-2">
          {selectedValueUpdated.title && (
            <div className="col-lg-12 col-sm-12">
              <b className="me-3 pt-1 ms-2">Filtered By:</b>
              <Chip
                label={selectedValueUpdated.title}
                onDelete={() => handleDeleteProgramChips(selectedValueUpdated)}
                className="mb-2 me-1 mt-2"
                sx={{
                  color: "white",
                }}
              />
              <span
                className="anchor-style ms-2 pt-1"
                onClick={() => handleClearFilterall(selectedValueUpdated)}
                style={{ color: get_root_value("--portal-theme-primary") }}
              >
                Clear All
              </span>
            </div>
          )}
        </div>

        <Card style={{ overflowX: "auto" }}>
          <div className="row">
            <div className="col-sm-12 col-md-6 d-flex flex-column align-items-start mt-1">
              <TablePagination
                rowsPerPageOptions={[50, 100, 150]}
                component="div"
                count={totalMembers}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Pagination
                count={totalPages + 1}
                page={pageCount}
                defaultPage={0}
                onChange={handleChangePages}
                className="pagination-style"
              />
            </div>
            <div className="col-sm-12 col-md-6 ">
              <UserListToolbars
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                onSearch={handleSearchName}
              />
            </div>
          </div>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.map((row, i) => {
                  const {
                    id,
                    name,
                    status,
                    lastName,
                    Username,
                    profile,
                    goal,
                    address,
                    type,
                    memberCount,
                    amount,
                    transactionDate,
                    currency,
                    transaction_request_type,
                    transaction_type,
                    count,
                    transaction_mode,
                    commission_amount,
                    agrement_pdf_url,
                    market_affiliate_name,
                    market_affiliate_commission,
                  } = row;
                  const isItemSelected = selected.indexOf(name) !== -1;

                  let transaction_title = "";
                  if (transaction_type == "payment_request") {
                    transaction_title = Username?.payment_request
                      ? "Payment Request (" +
                        Username.payment_request?.request_title +
                        " | "
                      : "N/A";
                  } else if (transaction_type == "clickfunnels") {
                    transaction_title =
                      "Click Funnels (" + Username.plan_info?.plan_title;
                  } else if (transaction_type == "quest") {
                    transaction_title = Username.sale_page_info
                      ? "Quest (" +
                        Username.mission_info?.title +
                        " | " +
                        Username.plan_info?.plan_title +
                        " | "
                      : "";
                  } else if (transaction_type == "payment_plan") {
                    transaction_title = Username.sale_page?.sale_page_title
                      ? "Sale Page (" +
                        Username.sale_page?.sale_page_title +
                        " | " +
                        Username.plan_info?.plan_title +
                        " | "
                      : "";
                  }

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell align="left">{count}</TableCell>
                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Tooltip
                            key={i}
                            title={
                              status === "succeeded" ? "Succeeded" : "Failed"
                            }
                            arrow
                            className="anchor-style"
                          >
                            <Typography variant="subtitle2" noWrap>
                              <CircleIcon
                                className={`${
                                  status === "succeeded"
                                    ? "Nurture_online"
                                    : "support_notification_sidebar_page"
                                }`}
                              />
                              {htmlDecode(
                                Username?.member_info
                                  ? Username?.member_info.first_name +
                                      " " +
                                      Username?.member_info.last_name
                                  : "N/A"
                              )}
                            </Typography>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {convertCurrencyToSign(currency) + amount}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {transaction_request_type === "onetime"
                          ? `${
                              transaction_title
                                ? transaction_title + "OneTime )"
                                : "N/A"
                            } `
                          : `${
                              transaction_title
                                ? transaction_title + "Recurring )"
                                : "N/A"
                            } `}
                        {/* {Capitalize(htmlDecode(transaction_type))} */}
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" noWrap>
                          {commission_amount
                            ? convertCurrencyToSign(currency) +
                              commission_amount.toFixed(2)
                            : convertCurrencyToSign(currency) + "0.00"}
                        </Typography>
                      </TableCell>{" "}
                      <TableCell>
                        <Typography variant="subtitle2" noWrap>
                          {transaction_mode}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" noWrap>
                          {agrement_pdf_url && (
                            <a
                              href={s3baseUrl + agrement_pdf_url}
                              target="_blank"
                              className="anchor-style"
                            >
                              Preview
                            </a>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="white-space-nowrap"
                      >
                        {market_affiliate_name}
                      </TableCell>{" "}
                      <TableCell
                        component="th"
                        scope="row"
                        className="white-space-nowrap"
                      >
                        {market_affiliate_commission}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="white-space-nowrap"
                      >
                        {transactionDate}
                      </TableCell>{" "}
                      {/* <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={status === "succeeded" ? "success" : "error"}
                        >
                          {status === "succeeded" ? "Successful" : "Failed"}
                        </Label>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={totalMembers}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Pagination
            count={totalPages + 1}
            page={pageCount}
            defaultPage={0}
            onChange={handleChangePages}
            className="pagination-style"
          />
        </Card>
        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filter Transactions"
          componentToPassDown={
            <TransactionFilterDrawer
              value={selectedValue}
              handleChange={handleChange}
              dataList={getConsultantListing}
              setPage={setPage}
              setPageCount={setPageCount}
              setSelectedValue={setSelectedValue}
              onCloseDrawer={handleCloseFilterDrawer}
              setSelectedValueUpdated={setSelectedValueUpdated}
            />
          }
        />
      </div>
    </>
  );
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import { missionMemberReportListApiV1 } from "src/DAL/AssessmentApi/assessmentApi";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { dd_date_format } from "src/utils/constants";
import FilterListIcon from "@mui/icons-material/FilterList";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import InprogressMissionsReportFilter from "./InprogressMissionsReportFilter";
import FilteredChip from "src/components/GeneralComponents/FilteredChip";
import CustomAvatarColorCode from "src/components/CustomAvatarColorCode";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_FILTER = {
  mission_select: null,
  from_day: 1,
  to_day: 1,
  search_text: "",
};

export default function MissionMemberReportList({ type }) {
  const navigate = useNavigate();
  const { isAccess } = useContentSetting();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [formDataUpdated, setformDataUpdated] = useState({});

  const [filterData, setFilterData] = useState(() => {
    try {
      const savedFilter = localStorage.getItem("mission_member_report_filter");
      return savedFilter ? JSON.parse(savedFilter) : EMPTY_FILTER;
    } catch (error) {
      console.error(
        "Error parsing saved filter data from localStorage:",
        error
      );
      return EMPTY_FILTER;
    }
  });

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);

    let postData = { ...filterData, search_text: searchText };

    if (postData?.mission_select) {
      postData.from_day = parseInt(filterData.from_day) || 1;
      postData.to_day =
        parseInt(filterData.to_day) ||
        filterData?.mission_select?.mission_duration ||
        1;
    }
    localStorage.setItem(`report_member${type}`, searchText);

    setFilterData(postData);
    getConsultantListing(postData);

    handleCloseDrawer();
  };

  const handle_chips_data = (flterData) => {
    let chipData = { ...flterData };
    delete chipData.page;

    if (chipData.mission_select) {
      let days = `Days : ${chipData.from_day} - ${chipData.to_day}`;
      chipData.days = {
        chip_label: days,
        chip_value: days,
      };
    }

    let newChipdata = {};

    if (chipData?.mission_select) {
      newChipdata = {
        mission_select: chipData?.mission_select,
        days: chipData?.days,
      };
    }

    setformDataUpdated(newChipdata);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    data.search_text = searchText;
    if (!data.days) {
      data.mission_select = null;
      data.from_day = 1;
      data.to_day = 1;
    }
    setFilterData(data);
    getConsultantListing(data);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setSearchText("");
    setFilterData(EMPTY_FILTER);
    getConsultantListing(EMPTY_FILTER);
    handleCloseDrawer();
  };

  const getConsultantListing = async (_filterData) => {
    let newData = _filterData ? _filterData : EMPTY_FILTER;
    if (type != "in_progress") {
      newData = { search_text: newData?.search_text };
      setformDataUpdated({});
      localStorage.removeItem("mission_member_report_filter");
    }

    setIsLoading(true);
    let postData = {};

    if (newData && newData?.mission_select) {
      postData = {
        from_day: parseInt(newData?.from_day),
        to_day: parseInt(newData?.to_day),
        mission_id: newData?.mission_select ? newData?.mission_select?._id : "",
      };
    }

    console.log("newData __newData", newData);
    try {
      setIsLoading(true);
      let include_members =
        isAccess.show_members_list_for_payment_request == "all_members"
          ? "all"
          : "nurture";
      const result = await missionMemberReportListApiV1(
        page,
        rowsPerPage,
        newData?.search_text,
        include_members,
        type,
        postData
      );

      if (result.code === 200) {
        const data = result.members.map((val, index) => {
          let UserName = "N/A";
          let email = "N/A";
          if (val?.member && Object.keys(val?.member).length > 0) {
            UserName = val?.member?.first_name + " " + val?.member?.last_name;
            email = val?.member?.email;
          }
          return {
            ...val,
            Username: UserName,
            email: email,
            mission: val?.mission_info?.title,
            mission_completed_date: dd_date_format(val?.mission_completed_date),
            mission_start_date: dd_date_format(val?.mission_start_date),
          };
        });

        handle_chips_data(_filterData);
        setUserList(data);
        setTotalPages(result.total_pages);
        setTotalCount(result.total_member_count);
        setIsLoading(false);
        localStorage.setItem(
          "mission_member_report_filter",
          JSON.stringify(newData)
        );
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const handleEdit = (value) => {
    if (type == "completed") {
      navigate(
        `/mission-member-report/mission/mission-detail/${value?.member?._id}/${value?.mission_info?._id}`,
        {
          state: value,
        }
      );
    } else {
      navigate(
        `/inprogress-mission-report/mission/mission-detail/${value?.member?._id}/${value?.mission_info?._id}`,
        {
          state: value,
        }
      );
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteDoc.id;
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  useEffect(() => {
    let search_text = localStorage.getItem("report_member" + type);
    if (search_text) {
      setSearchText(search_text);
      getConsultantListing(filterData);
    } else {
      setSearchText("");
      getConsultantListing(filterData);
    }
  }, [page, rowsPerPage, type]);

  useEffect(() => {
    setPage(0);
    setPageCount(1);
  }, [type]);

  const MENU_OPTIONS = [
    {
      label: "View Missions",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
  ];
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  let TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },

    { id: "number", label: "#", type: "number" },
    {
      id: "profile",
      label: "Profile",
      renderData: (row) => {
        return (
          <span onClick={() => handleEdit(row)} className="pointer">
            <CustomAvatarColorCode
              alt={row?.member?.first_name}
              src={s3baseUrl + row?.member?.profile_image}
              colorCode={row?.current_badge_level?.color_code}
            />
          </span>
        );
      },
    },
    {
      id: "email",
      label: "Name",
      renderData: (row) => {
        return (
          <div className="d-flex align-items-center">
            <span onClick={() => handleEdit(row)} className="pointer mr-2">
              {htmlDecode(row.Username)}
            </span>
          </div>
        );
      },
    },
    {
      id: "email",
      label: "User Current Badge Level",
      renderData: (row) => {
        return (
          <div className="d-flex align-items-center">
            <span onClick={() => handleEdit(row)} className="pointer mr-2">
              {htmlDecode(row?.current_badge_level?.title)}
            </span>

            <img
              className="avatar-adjust ms-2"
              style={{ width: 20, height: 20 }}
              alt={row?.current_badge_level?.title}
              src={s3baseUrl + row?.current_badge_level?.icon?.thumbnail_1}
            />
          </div>
        );
      },
    },
    {
      id: "email",
      label: "Mission Acceptance Time User Badge Level ",
      renderData: (row) => {
        return (
          <div className="d-flex align-items-center">
            <span onClick={() => handleEdit(row)} className="pointer mr-2">
              {htmlDecode(row?.accept_time_badge_details?.title)}
            </span>

            <img
              className="avatar-adjust ms-2"
              style={{ width: 20, height: 20 }}
              alt={row?.accept_time_badge_details?.title}
              src={
                s3baseUrl + row?.accept_time_badge_details?.icon?.thumbnail_1
              }
            />
          </div>
        );
      },
    },
    { id: "email", label: "Email" },
    { id: "mission", label: "Mission Title" },

    { id: "mission_duration", label: "Mission Duration (Days)" },
    { id: "mission_attracted_coins", label: "Coins Earned" },
    { id: "mission_reward_coins", label: "Achievable Coins" },
    { id: "mission_start_date", label: "Mission Start Date" },
    { id: "mission_completed_date", label: "Mission Completed Date" },
  ];
  let filteredTableHead = [];

  filteredTableHead =
    type == "in_progress" ? TABLE_HEAD.slice(0, -1) : TABLE_HEAD;

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h2>{`${
              type == "completed" ? "Completed" : "Inprogress"
            } Missions Report`}</h2>
          </div>
          {type != "completed" && (
            <div className="col-lg-4 col-sm-12 text-end">
              <button
                className="small-contained-button"
                onClick={handleOpenDrawer}
              >
                <FilterListIcon className="me-2" />
                Filter
              </button>
            </div>
          )}
        </div>

        <div className="col-12 mt-4">
          {type != "completed" && (
            <FilteredChip
              data={formDataUpdated}
              tempState={filterData}
              EMPTY_FILTER={EMPTY_FILTER}
              onDeleteChip={handleDeleteChip}
              onClear={handleClearFilter}
            />
          )}
          <CustomMUITable
            TABLE_HEAD={filteredTableHead}
            data={userList}
            selected={selected}
            MENU_OPTIONS={MENU_OPTIONS}
            setSelected={setSelected}
            className="card-with-background"
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            pagePagination={true}
          />
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <>
            <InprogressMissionsReportFilter
              filterData={filterData}
              setFilterData={setFilterData}
              handleChangeOthers={handleChangeOthers}
              searchSubmitFilter={searchFunction}
              handleClearFilter={handleClearFilter}
            />
          </>
        }
      />
    </>
  );
}

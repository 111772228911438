import { Icon } from "@iconify/react";
import { Avatar, CircularProgress, Tooltip } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CodeIcon from "@mui/icons-material/Code";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { s3baseUrl } from "src/config/config";
import {
  decode_markdown,
  get_date_with_user_time_zone,
  get_short_string,
  sort_array_based_on_createdAt,
} from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "src/components/CustomDrawer/CustomDrawer";
import MicIcon from "@mui/icons-material/Mic";
import { _get_chat_detail } from "src/DAL/Chat/Chat";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import NewWhatsAppUsers from "./NewWhatsAppUsers";
import {
  _initiate_chat_whatsapp,
  _whatsapp_chat_list,
} from "src/DAL/WhatsApp/WhatsApp";
import CustomAvatarColorCode from "src/components/CustomAvatarColorCode";

export default function WhatsAppChatList(props) {
  const {
    setSelectedChat,
    selectedChat,
    setChatList,
    chatList,
    setState,
    state,
    isLoadingChats,
    isLoadingMoreChats,
    totalPagesChats,
    loadMoreData,
    loadMoreChatPath,
    pageNumberChat,
  } = props;
  const { receiver_info } = selectedChat;
  const [drawerState, setDrawerState] = useState(false);
  const { userInfo } = useContentSetting();
  const observer = useRef();
  const navigate = useNavigate();

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleUnread = (chat_type) => {
    setState((old) => ({ ...old, chat_type }));
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
    navigate(`/whatsapp-chat`);
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMoreChats) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPagesChats > pageNumberChat) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [
      isLoadingMoreChats,
      totalPagesChats,
      pageNumberChat,
      loadMoreChatPath,
      observer,
    ]
  );

  const handleAddChat = async (user_id) => {
    const already_exist = chatList.find(
      (chat) => chat.receiver_info._id == user_id
    );
    if (already_exist) {
      setSelectedChat(already_exist);
      handleCloseDrawer();
      return;
    } else {
      let postData = { receiver_id: user_id };
      const result = await _initiate_chat_whatsapp(postData);
      setChatList((prev) => {
        return [result.data, ...prev];
      });
      setSelectedChat(result.data);
    }
    handleCloseDrawer();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((old) => ({ ...old, [name]: value }));
  };

  sort_array_based_on_createdAt(chatList);

  return (
    <>
      <div className="col-12 col-md-4 chat-left-part whatsapp-chat">
        <div className="chat-left-header mb-3 d-flex">
          <div className="chat-search-box w-100">
            <Icon
              fontSize={14}
              className="chat-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="chat-search-input"
              type="text"
              placeholder={`${
                state.chat_type == "unread"
                  ? "Search unread chats"
                  : "Search chats"
              }`}
              value={state.search_text}
              name="search_text"
              onChange={handleChange}
            />
          </div>
          <Tooltip title={`Unread chats filter`}>
            <div
              className={`filter-icon ${
                state.chat_type == "unread" ? "selected" : ""
              }`}
              onClick={() => {
                handleUnread(state.chat_type == "all" ? "unread" : "all");
              }}
            >
              <Icon fontSize={20} icon="octicon:filter-24" />
            </div>
          </Tooltip>
        </div>
        <div className="position-relative">
          <div className="chat-list">
            {isLoadingChats ? (
              <div className="centered-chat-loader">
                <CircularProgress
                  className="mt-3 "
                  style={{ width: 30, height: 30 }}
                  color="primary"
                />
              </div>
            ) : chatList.length > 0 ? (
              <>
                {chatList?.map((chat, i) => {
                  return (
                    <div
                      onClick={() => handleChatClick(chat)}
                      className={
                        chat.receiver_info._id === receiver_info?._id
                          ? "chat-list-user selected-chat d-flex p-2"
                          : "chat-list-user hover-effect d-flex p-2"
                      }
                      key={i}
                    >
                      <div>
                        <div className="user-profile">
                          <CustomAvatarColorCode
                            src={s3baseUrl + chat.receiver_info.profile_image}
                            alt={"photo"}
                            colorCode={
                              chat?.receiver_info?.badge_info?.color_code
                            }
                            width={45}
                            height={45}
                          />
                        </div>
                      </div>
                      <div className="chat-profile-data">
                        <div className="chat-profile-name ps-2 text-capitalize chat-profile-name-padding">
                          {chat.receiver_info.first_name +
                            " " +
                            chat.receiver_info.last_name}
                        </div>
                        <div className="chat-last-message ps-2">
                          {chat?.message_type == "image" ? (
                            <>
                              <PhotoCameraIcon fontSize="10" /> Photo
                            </>
                          ) : chat?.message_type == "video" ? (
                            <>
                              <PlayCircleIcon fontSize="10" /> Video
                            </>
                          ) : chat?.message_type == "audio" ? (
                            <>
                              <MicIcon fontSize="18" /> Voice Message
                            </>
                          ) : chat?.message_type == "html" ? (
                            <>
                              <span className="new_message_text">
                                a new message sent
                              </span>
                            </>
                          ) : (
                            chat.last_message &&
                            get_short_string(
                              htmlDecode(
                                decode_markdown(chat.last_message.message)
                              )
                            )
                          )}
                        </div>
                      </div>
                      {chat.last_message_date_time && (
                        <div className="chat-last-time text-end ps-2">
                          <Tooltip
                            title={get_date_with_user_time_zone(
                              chat.last_message_date_time,
                              "DD MMM YYYY [at] hh:mm A",
                              userInfo.time_zone,
                              userInfo.adminTimeZone
                            )}
                            arrow
                          >
                            <span className="">
                              {get_date_with_user_time_zone(
                                chat.last_message_date_time,
                                "DD-MM-YYYY hh:mm A",
                                userInfo.time_zone,
                                userInfo.adminTimeZone
                              )}
                            </span>
                          </Tooltip>
                        </div>
                      )}
                      {chat?.sender_info?.unread_message_count > 0 && (
                        <div className="chat-unread-count">
                          {chat?.sender_info?.unread_message_count}
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <RecordNotFound title="Chats not found" />
            )}
            {totalPagesChats > pageNumberChat && !isLoadingChats ? (
              <div className="col-12 text-center p-2">
                <span
                  ref={lastBookElementRef}
                  className=" mt-3"
                  onClick={loadMoreData}
                  id="load-more-feed"
                >
                  {isLoadingMoreChats ? "Loading..." : "Load More"}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>

          {!drawerState && (
            <Tooltip title="New Chat" placement="top">
              <button className="new-chat-button" onClick={handleOpenDrawer}>
                <AddIcon />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`New Chat`}
        componentToPassDown={
          <NewWhatsAppUsers
            onCloseDrawer={handleCloseDrawer}
            handleAddChat={handleAddChat}
          />
        }
      />
    </>
  );
}

import { useSnackbar } from "notistack";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { audio_1, audio_2 } from "src/assets";
import { s3baseUrl } from "src/config/config";
import {
  _check_chat_existing,
  _get_chat_history,
  _get_chat_list_history,
  _get_chat_message,
  _list_chat_with_event_delegate,
  _read_message,
  _unread_message,
} from "src/DAL/Chat/Chat";
import {
  _list_of_member_for_chat_against_event,
  load_more_member_for_chat_against_event,
} from "src/DAL/LiveEvents/LiveEvents";
import UserBox from "src/Hooks/ChatContext/components/Users-Box/UserBox";
import { useContentSetting } from "../ContentSettingState";
import ChatBox from "./components/Chat-Box/ChatBox";
import { htmlDecode } from "src/utils/convertHtml";
import { get_short_string, decode_markdown } from "src/utils/constants";

const CreateChatContext = React.createContext();

//----------------------
export const useChat = () => useContext(CreateChatContext);
export function ChatContext({ children }) {
  const { userInfo, adminTimeZone, socket, setNotificationsCount } =
    useContentSetting();
  const params = useParams();
  const observer = useRef();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  /* ------------------------------------------------------
  ---------------------------| States |--------------------
  ------------------------------------------------------- */
  // show chat list
  const [isChatShow, setIsChatShow] = useState(false);

  // show list of users
  const [isListExpanded, setIsListExpanded] = useState(false);
  // list popup loader
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  // show chat pop up
  const [isExpandedChat, setIsExpandedChat] = useState(false);
  // chat popup loader
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [onlineOfflineData, setOnlineOfflineData] = useState({});
  const [loadMore, setLoadMore] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loadMoreUserPath, setLoadMoreUserPath] = useState("");
  const [totalPagesChats, setTotalPagesChats] = useState(0);
  const [pageNumberChat, setPageNumberChat] = useState(0);
  const [isLoadingMoreChats, setIsLoadingMoreChats] = useState(false);
  const [loadMoreChatPath, setLoadMoreChatPath] = useState(
    "api/chat/list_chat_with_event_delegate/list/v1?page=0&limit=50"
  );
  // store list of loaded chat
  const [chatMessages, setChatMessages] = useState([]);
  // current selected chat object
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedChatUser, setSelectedChatUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [unreadChatCounts, setUnreadChatCounts] = useState(0);
  const [messagesList, setMessagesList] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);

  // online and offline members against live events
  const [onlineMembers, setOnlineMembers] = useState([]);
  const [onlineMembersCount, setOnlineMembersCount] = useState(0);
  const [offlineMembers, setOfflineMembers] = useState([]);
  const [offlineMembersCount, setOfflineMembersCount] = useState(0);
  const [onlinePortalMembers, setOnlinePortalMembers] = useState([]);
  const [onlinePortalMembersCount, setOnlinePortalMembersCount] = useState(0);
  const [offlinePortalMembers, setOfflinePortalMembers] = useState([]);
  const [offlinePortalMembersCount, setOfflinePortalMembersCount] = useState(0);

  // chat history
  const [chatList, setChatList] = useState([]);
  const [eventSlug, setEventSlug] = useState("");
  const [whichChatListApiCall, setwhichChatListApiCall] = useState(false);
  const [eventApiObject, setEventApiObject] = useState({});

  /* ------------------------------------------------------
  -------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleSetIsChatShow = (value) => {
    setIsChatShow(value);
  };

  const handleSetEventSlug = (value) => {
    setEventSlug(value);
  };

  const handleSetIsListExpanded = (value) => {
    setIsListExpanded(value);
  };

  const handleSetIsLoadingList = (value) => {
    setIsLoadingList(value);
  };

  const handleSetIsExpandedChat = (value) => {
    setIsExpandedChat(value);
  };

  const handleSetIsLoadingChat = (value) => {
    setIsLoadingChat(value);
  };

  const handleSetSelectedChat = (value) => {
    setSelectedChatUser(value?.receiver_profile);
    setSelectedChat(value);
  };

  const handleSetSelectedUser = (value) => {
    setSelectedUser(value);
  };

  const handleSetChatMessages = (value) => {
    setChatMessages(value);
  };

  const handleCloseChat = () => {
    setSelectedChat(null);
    setSelectedChatUser(null);
    setSelectedUser(null);
  };

  const handleUpdateChatObject = (user_id, chat) => {
    setOnlineMembers((online_members) => {
      let updated_members = online_members.map((user) => {
        if (user._id === user_id) {
          return { ...user, chat: chat };
        } else {
          return user;
        }
      });
      return updated_members;
    });

    setOfflineMembers((offline_members) => {
      let updated_members = offline_members.map((user) => {
        if (user._id === user_id) {
          return { ...user, chat: chat };
        } else {
          return user;
        }
      });
      return updated_members;
    });

    handleSetSelectedUser(null);
  };

  // controllers
  const get_profile_from_member = (member_id, members = []) => {
    const member_profile = members.find(
      (member) => member._id._id === member_id
    );
    return member_profile;
  };

  const get_receiver_profile = (members = []) => {
    const profile = members.find((m) => m._id?._id !== userInfo._id);
    return { ...profile, ...profile._id };
  };

  const get_formatted_message = (message_obj) => {
    const receiver_profile = get_profile_from_member(message_obj.receiver_id);
    const sender_profile = get_profile_from_member(message_obj.sender_id);
    const is_self = userInfo._id === message_obj.sender_id ? true : false;
    return { ...message_obj, is_self, sender_profile, receiver_profile };
  };

  const get_users_list_against_event = async (
    event_slug,
    query = "",
    selectedTab
  ) => {
    let type = "online";
    if (selectedTab == 0) {
      type = "offline";
    }
    setIsLoadingUsers(true);
    const result = await _list_of_member_for_chat_against_event(
      event_slug ? event_slug : eventSlug,
      query,
      type
    );

    if (result.code === 200) {
      const online_other_members = result.online_member.filter(
        (member) => member._id !== userInfo._id
      );

      const offline_other_members = result.offline_member.filter(
        (member) => member._id !== userInfo._id
      );

      const online_members_with_receivers = online_other_members.map((m) => {
        if (m.chat.member) {
          const profile = get_receiver_profile(m.chat.member);
          return { ...m, chat: { ...m.chat, receiver_profile: profile } };
        } else {
          return m;
        }
      });

      const offline_members_with_receivers = offline_other_members.map((m) => {
        if (m.chat) {
          const profile = get_receiver_profile(m.chat.member);
          return { ...m, chat: { ...m.chat, receiver_profile: profile } };
        } else {
          return m;
        }
      });

      setOnlineMembers(online_members_with_receivers);
      setOnlineMembersCount(result.total_online_members);
      setOfflineMembersCount(result.total_offline_members);
      setOfflineMembers(offline_members_with_receivers);
      setOnlinePortalMembers(online_members_with_receivers);
      setOnlinePortalMembersCount(result.total_online_members);
      setOfflinePortalMembersCount(result.total_offline_members);
      setOfflinePortalMembers(offline_members_with_receivers);
      setTotalPages(result.total_page);
      setLoadMoreUserPath(result.load_more_url);
      setPageNumber(1);
      setIsLoadingUsers(false);
    } else {
      setIsLoadingUsers(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_more_users_list_against_event = async (
    load_more,
    setIsLoadingMore
  ) => {
    const result = await load_more_member_for_chat_against_event(load_more);
    if (result.code === 200) {
      const online_other_members = result.online_member.filter(
        (member) => member._id !== userInfo._id
      );

      const offline_other_members = result.offline_member.filter(
        (member) => member._id !== userInfo._id
      );

      const online_members_with_receivers = online_other_members.map((m) => {
        if (m.chat.member) {
          const profile = get_receiver_profile(m.chat.member);
          return { ...m, chat: { ...m.chat, receiver_profile: profile } };
        } else {
          return m;
        }
      });

      const offline_members_with_receivers = offline_other_members.map((m) => {
        if (m.chat) {
          const profile = get_receiver_profile(m.chat.member);
          return { ...m, chat: { ...m.chat, receiver_profile: profile } };
        } else {
          return m;
        }
      });

      setOnlineMembers((old) => [...old, ...online_members_with_receivers]);
      setOnlineMembersCount(result.total_online_members);
      setOfflineMembersCount(result.total_offline_members);
      setOfflineMembers((old) => [...old, ...offline_members_with_receivers]);
      setOnlinePortalMembers((old) => [
        ...old,
        ...online_members_with_receivers,
      ]);
      setOnlinePortalMembersCount(result.total_online_members);
      setOfflinePortalMembersCount(result.total_offline_members);
      setOfflinePortalMembers((old) => [
        ...old,
        ...offline_members_with_receivers,
      ]);
      setTotalPages(result.total_page);
      setLoadMoreUserPath(result.load_more_url);
      setPageNumber((page) => page + 1);
      setIsLoadingUsers(false);
      setIsLoadingMore(false);
    } else {
      setIsLoadingUsers(false);
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  // yaha sy api call ho rhi ha
  const get_chat_list = async (postData) => {
    let data = postData;
    setIsLoadingChats(true);
    if (!data) {
      data = eventApiObject;
    }
    const result = await _list_chat_with_event_delegate(data);
    if (result.code === 200) {
      const _chats = result.chat.map((chat) => {
        const receiver_profile = get_receiver_profile(chat.member);
        const my_profile = get_profile_from_member(userInfo._id, chat.member);
        return {
          ...chat,
          receiver_profile,
          my_profile,
        };
      });
      setChatList(_chats);
      setTotalPagesChats(result.total_pages);
      setLoadMoreChatPath(result.load_more_chat);
      setUnreadChatCounts(result.total_unread_chats);
      setPageNumberChat(1);
      setIsLoadingChats(false);
    } else {
      setIsLoadingChats(false);

      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const get_chat_history_list = async (
    postData,
    load_more = "api/chat/list_chat_with_event_delegate/list/v1?page=0&limit=50",
    chats
  ) => {
    let data = JSON.stringify(postData) == "{}" ? eventApiObject : postData;
    const result = await _get_chat_list_history(data, load_more);
    if (result.code === 200) {
      const _chats = result.chat.map((chat) => {
        const receiver_profile = get_receiver_profile(chat.member);
        const my_profile = get_profile_from_member(userInfo._id, chat.member);
        return {
          ...chat,
          receiver_profile,
          my_profile,
        };
      });
      if (chats) {
        var newArray = chats.concat(_chats);
        setChatList(newArray);
      } else {
        setChatList(_chats);
      }
      setTotalPagesChats(result.total_pages);
      setLoadMoreChatPath(result.load_more_chat);
      setPageNumberChat((page) => page + 1);
      setUnreadChatCounts(result.total_unread_chats);
      scroll_to_bottom_on_message();
      setIsLoadingMoreChats(false);
    } else {
      setIsLoadingMoreChats(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const mark_as_read = async (chat_id) => {
    const result = await _read_message(chat_id);
    if (result.code === 200) {
      setChatList((old) =>
        old.map((chat) => {
          let count = chat.my_profile.unread_message_count;
          if (chat._id == chat_id) {
            count = 0;
          }
          return {
            ...chat,
            my_profile: {
              ...chat.my_profile,
              unread_message_count: count,
            },
          };
        })
      );
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const mark_as_unread = async (message) => {
    const result = await _unread_message(message._id);
    if (result.code === 200) {
      setChatList((old) =>
        old.map((chat) => {
          let count = chat.my_profile.unread_message_count;
          if (chat._id == selectedChat._id) {
            count++;
          }
          return {
            ...chat,
            my_profile: {
              ...chat.my_profile,
              unread_message_count: count,
            },
          };
        })
      );
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const STOP_CHAT_FORCEFULLY = (chat_id) => {
    setSelectedChat(null);
  };

  const is_chat_screen_open = () => {
    if (
      (location.pathname.search("/chat") >= 0 ||
        location.pathname.search("/whatsapp-chat")) >= 0 ||
      location.pathname.search("/broadcast-chat") >= 0
    ) {
      return true;
    }
    return false;
  };

  const showNewMessageNotification = (data) => {
    let message_type = data.message_obj.message_type;
    let notification_string = "";
    if (data.message_obj.message) {
      notification_string = get_short_string(
        decode_markdown(data.message_obj.message)
      );
    }

    if (message_type !== "general") {
      let find_sender = data.chat_obj.member.find(
        (sender) => sender._id._id == data.message_obj.sender_id
      );
      let text = "Photo";
      if (message_type == "video") {
        text = "Video";
      } else if (message_type == "html") {
        text = "Message";
      } else if (message_type == "audio") {
        text = "Audio message";
      }
      notification_string = `${find_sender.first_name} ${find_sender.last_name} sent a new ${text}. `;
    }

    let receiver_ = get_receiver_profile(data.chat_obj.member);
    // checking and calling notification bellow
    const notification = () => {
      const _notification = new Notification("New Notification", {
        body: notification_string,
        icon: s3baseUrl + receiver_.profile_image,
      });
      const tune = new Audio(audio_1);
      // const tune = new Audio(audio_2);
      tune.play();
      _notification.onclick = async function (event) {
        setSelectedChat((c) => {
          return {
            ...data.chat_obj,
            receiver_profile: receiver_,
          };
        });

        parent.focus();
        window.focus(); //just in case, older browsers
        this.close();
      };
    };

    // check for permission

    if (!("Notification" in window)) {
      // Check if the browser supports notifications
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      notification();
      // …
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission

      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          notification();
        }
      });
    }
  };

  // const scroll_to_bottom_on_message = () => {
  //   let scroll = false;
  //   const chat_box = document.getElementsByClassName("user-chat-box-body")[0];
  //   const scroll_top = chat_box.scrollTop;
  //   const scroll_height = chat_box.scrollHeight;
  //   const offset_height = chat_box.offsetHeight;
  //   const available_scroll = scroll_height - offset_height - 100;
  //   chat_box.scrollBy({ top: scroll_height, behavior: "smooth" });
  // };
  const scroll_to_bottom_on_message = () => {
    const chat_box = document.getElementsByClassName("user-chat-box-body")[0];
    chat_box?.scroll({ top: chat_box.scrollHeight, behavior: "smooth" });
  };

  const change_dom_scrollable_status = (status) => {
    const chat_box = document.getElementsByClassName("user-chat-box-body")[0];
    chat_box?.scrollBy({ top: 100, behavior: "smooth" });
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (!isLoadingChat && count > messagesList.length && !isLoadingMore) {
            loadMoreData(loadMore);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, count, loadMore, observer, messagesList, isLoadingChat]
  );

  const loadMoreChat = async (path) => {
    setIsLoadingMore(true);
    const result = await _get_chat_message(path);
    if (result.code === 200) {
      const formatted_messages = result.message.map((message_obj) =>
        get_formatted_message(message_obj)
      );
      let scrollBottom = 0;
      let chat_box = document.getElementById("chat_box_body");
      if (chat_box) {
        scrollBottom = chat_box.scrollHeight - chat_box.scrollTop;
      }
      setMessagesList((c) => [...formatted_messages, ...c]);
      setCount(result.count);
      setLoadMore(result.load_more);
      setIsLoadingMore(false);
      chat_box.scrollTop = chat_box.scrollHeight - scrollBottom;
    } else {
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleGetChat = async (chat_id) => {
    const first_load_url = `api/chat/list_message/${chat_id}?page=0&limit=50`;
    setIsLoadingChat(true);
    const result = await _get_chat_message(first_load_url);
    if (result.code === 200) {
      let is_devider = true;
      const formatted_messages = result.message.map((message_obj) => {
        if (message_obj.status === "delivered" && is_devider) {
          is_devider = false;
          return { ...get_formatted_message(message_obj), is_devider: true };
        }
        return get_formatted_message(message_obj);
      });

      setMessagesList(formatted_messages);
      setCount(result.count);
      setLoadMore(result.load_more);
      setIsLoadingChat(false);
      let chat_box = document.getElementById("chat_box_body");
      chat_box.scrollTop = chat_box.scrollHeight;
      mark_as_read(chat_id);
    } else {
      setIsLoadingChat(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const loadMoreData = () => {
    loadMoreChat(loadMore);
  };

  const get_receiver_member_profile = (members = []) => {
    const profile = members.find((m) => m._id !== userInfo._id);
    return { ...profile, ...profile?._id };
  };

  const get_my_profile = (members = []) => {
    const profile = members.find((m) => m._id == userInfo._id);

    return { ...profile, ...profile?._id };
  };

  const handleAddChat = async (user_id, handleCloseDrawer = () => {}) => {
    const already_exist = chatList.find((chat) =>
      chat.member.some((user) => user._id?._id == user_id)
    );
    if (already_exist) {
      handleSetSelectedChat(already_exist);
      handleCloseDrawer();
      return;
    } else {
      const result = await _check_chat_existing(user_id);
      let selected_obj = {};
      if (result.code === 200 && result.is_chat_exist) {
        let my_profile = get_my_profile(result.chat.member);
        let receiver_profile = get_receiver_member_profile(result.chat.member);
        selected_obj = {
          ...result.chat,
          my_profile,
          receiver_profile,
        };
      } else {
        let my_profile = {
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          profile_image: userInfo.profile_image,
          unread_message_count: 0,
          _id: {
            _id: userInfo._id,
            is_online: true,
          },
        };
        let receiver_profile = { ...result.user_info, unread_message_count: 0 };
        selected_obj = {
          my_profile,
          receiver_profile,
          member: [my_profile, receiver_profile],
        };
      }
      setChatList((prev) => {
        return [selected_obj, ...prev];
      });
      handleSetSelectedChat(selected_obj);
    }
    handleCloseDrawer();
    return true;
  };

  const receive_new_message = (data) => {
    const new_message = get_formatted_message(data.message_obj);
    let receiver_profile = get_receiver_profile(data.chat_obj.member);

    // setSelectedChat((c) => {
    //   if (!selectedChatUser) {
    //     setSelectedChatUser(receiver_profile);
    //   }
    //   if (is_chat_screen_open()) {
    //     // if chat history screen open
    //     // don't set selected chat in this case.
    //     // get_chat_list();
    //     get_chat_list();

    //     if (!c) {
    //       return {
    //         ...data.chat_obj,
    //         receiver_profile: receiver_profile,
    //       };
    //     }
    //     return c;
    //   } else {
    //     if (c) {
    //       // if already a chat is selected
    //       // don't set selected chat in this case.
    //       // otherwise it will replace the current one.

    //       if (c._id === data.chat_obj._id) {
    //       }

    //       return c;
    //     } else {
    //       return {
    //         ...data.chat_obj,
    //         receiver_profile: get_receiver_profile(data.chat_obj.member),
    //       };
    //     }
    //   }
    // });
    get_chat_list();
    if (
      (selectedChat && data.message_obj.chat_id === selectedChat._id) ||
      (selectedChatUser && data.message_obj.sender_id === selectedChatUser._id)
    ) {
      setMessagesList((old_messages) => [...old_messages, new_message]);
      scroll_to_bottom_on_message();
      mark_as_read(data.message_obj.chat_id);
    } else {
      setNotificationsCount((old) => old + 1);
      showNewMessageNotification(data);
    }

    // setOnlineMembers((online_members) => {
    //   const find_member = online_members.find(
    //     (user) => user.chat?._id === data.chat_id
    //   );
    //   if (find_member) {
    //     setSelectedChat((c) => find_member);
    //   }
    //   return online_members;
    // });
  };

  const receive_member_online = (data) => {
    if (selectedChatUser?._id == data.user_id) {
      setMessagesList((old_messages) =>
        old_messages.map((message) => ({ ...message, status: "delivered" }))
      );
    }
    setOnlineOfflineData({ is_online: true, ...data });
    if (offlineMembersCount > 0) {
      setOfflineMembersCount((prev) => {
        return prev - 1;
      });
    }

    setOnlineMembersCount((prev) => {
      return prev + 1;
    });

    setSelectedChatUser((user) => {
      if (user) {
        if (user._id === data.user_id) {
          return { ...user, is_online: true };
        } else {
          return user;
        }
      } else {
        return user;
      }
    });

    setOfflineMembers((offline_members) => {
      const find_member = offline_members.find(
        (user) => user._id === data.user_id
      );

      if (find_member) {
        setOnlineMembers((online_members) => {
          let remaining_members = online_members.filter(
            (user) => user._id !== data.user_id
          );
          return [{ ...find_member, is_online: true }, ...remaining_members];
        });

        return offline_members.filter((user) => user._id !== data.user_id);
      } else {
        return offline_members;
      }
    });

    setChatList((chat_list) => {
      const updated_chat = chat_list.map((chat) => {
        let my_profile = chat.my_profile;
        let receiver_profile = chat.receiver_profile;
        if (my_profile?._id === data.user_id) {
          my_profile.is_online = true;
        }
        if (receiver_profile._id === data.user_id) {
          receiver_profile.is_online = true;
          if (chat.last_message_status == "sent") {
            chat.last_message_status = "delivered";
          }
        }
        return { ...chat, my_profile, receiver_profile };
      });

      return updated_chat;
    });

    // setSelectedChat((chat) => {
    //   if (chat) {
    //     let my_profile = chat.my_profile;
    //     let receiver_profile = chat.receiver_profile;

    //     if (my_profile?._id === data.user_id) {
    //       my_profile.is_online = true;
    //     }

    //     if (receiver_profile._id === data.user_id) {
    //       receiver_profile.is_online = true;
    //     }
    //     return { ...chat, my_profile, receiver_profile };
    //   } else {
    //     return chat;
    //   }
    // });
  };

  const get_all_unread_count = (chat) => {
    let count = 0;

    chatList.forEach((chat) => {
      if (chat.my_profile?.unread_message_count) {
        count += chat.my_profile?.unread_message_count;
      }
    });

    return count;
  };

  const receive_member_offline = (data) => {
    setOnlineOfflineData({ is_online: false, ...data });
    setOfflineMembersCount((prev) => {
      return prev + 1;
    });
    if (onlineMembersCount > 0) {
      setOnlineMembersCount((prev) => {
        return prev - 1;
      });
    }

    setSelectedChatUser((user) => {
      if (user) {
        if (user._id === data.user_id) {
          return { ...user, is_online: false };
        } else {
          return user;
        }
      } else {
        return user;
      }
    });

    setOnlineMembers((online_members) => {
      const find_member = online_members.find(
        (user) => user._id === data.user_id
      );
      if (find_member) {
        setOfflineMembers((offline_members) => {
          let remaining_members = offline_members.filter(
            (user) => user._id !== data.user_id
          );
          return [{ ...find_member, is_online: false }, ...remaining_members];
        });

        return online_members.filter((user) => user._id !== data.user_id);
      } else {
        return online_members;
      }
    });
    setChatList((chat_list) => {
      const updated_chat = chat_list.map((chat) => {
        let my_profile = chat.my_profile;
        let receiver_profile = chat.receiver_profile;

        if (my_profile?._id === data.user_id) {
          my_profile.is_online = false;
        }

        if (receiver_profile._id === data.user_id) {
          receiver_profile.is_online = false;
        }
        return { ...chat, my_profile, receiver_profile };
      });

      return updated_chat;
    });

    // setSelectedChat((chat) => {
    //   if (chat) {
    //     let my_profile = chat.my_profile;
    //     let receiver_profile = chat.receiver_profile;

    //     if (my_profile?._id === data.user_id) {
    //       my_profile.is_online = false;
    //     }

    //     if (receiver_profile._id === data.user_id) {
    //       receiver_profile.is_online = false;
    //     }
    //     return { ...chat, my_profile, receiver_profile };
    //   } else {
    //     return chat;
    //   }
    // });
  };

  const handle_edit_message = async (data) => {
    setMessagesList((current_list) => {
      const updated_list = current_list.map((message_obj) => {
        if (message_obj._id === data.message_obj._id) {
          return {
            ...message_obj,
            message: data.message_obj.message,
            image: data.message_obj.image,
          };
        } else {
          return message_obj;
        }
      });
      return updated_list;
    });

    if (data.is_last_message) {
      let new_obj = {
        _id: data.message_obj.chat_id,
        last_message: data.message_obj.message,
        last_message_date_time: data.message_obj.message_date_time,
        message_id: data.message_obj._id,
        updatedAt: data.message_obj.updatedAt,
      };
      setChatList((prev) => {
        return prev.map((chat) => {
          if (data.message_obj.chat_id == chat._id) {
            return {
              ...chat,
              ...new_obj,
            };
          } else {
            return chat;
          }
        });
      });
    }
  };

  const handle_delete_message = async (data) => {
    setMessagesList((current_list) => {
      const updated_list = current_list.filter(
        (message_obj) => message_obj._id !== data.message_id
      );
      return updated_list;
    });
    if (data.is_last_message) {
      let new_obj = {
        _id: data.message_obj.chat_id,
        last_message: data.message_obj.message,
        last_message_date_time: data.message_obj.message_date_time,
        message_id: data.message_obj._id,
        updatedAt: data.message_obj.updatedAt,
      };
      setChatList((prev) => {
        return prev.map((chat) => {
          if (data.chat_id == chat._id) {
            return {
              ...chat,
              ...new_obj,
            };
          } else {
            return chat;
          }
        });
      });
    }
    // get_chat_list();
    get_chat_list();
  };
  useEffect(() => {
    setSelectedMessage(null);
    setMessagesList([]);
    setCount(0);
    //-------------------

    if (selectedChat) {
      if (selectedChat?._id) {
        handleGetChat(selectedChat?._id);
      } else {
        setMessagesList([]);
        setCount(0);
      }
    }
    if (localStorage.getItem("token")) {
      socket.on("send_chat_message_receiver", (data) => {
        receive_new_message(data);
      });
      socket.on("update_chat_message_receiver", (data) => {
        handle_edit_message(data);
      });
      socket.on("delete_chat_message_receiver", (data) => {
        handle_delete_message(data);
      });
      // socket.on("delete_chat_message_event_for_sender", (data) => {
      //   handle_delete_message(data);
      // });

      return () => {
        socket.off("send_chat_message_receiver");
        socket.off("update_chat_message_receiver");
        socket.off("delete_chat_message_receiver");
      };
    }
  }, [selectedChat, socket, eventApiObject, selectedUser]);

  useEffect(() => {
    if (socket.connected) {
      // user online offline handling
      // socket.on("member_online", (data) => {
      //   receive_member_online(data);
      // });
      // socket.on("member_offline", (data) => {
      //   receive_member_offline(data);
      // });
    }

    return () => {
      // socket.off("member_online");
      // socket.off("member_offline");
    };
  }, [socket?.connected, selectedChat, selectedChatUser]);

  // useEffect(() => {
  //   setLoadMoreChatPath(
  //     "api/chat/list_chat_with_event_delegate/list/v1?page=0&limit=50"
  //   );
  // }, [location.pathname]);

  const collection = {
    get_users_list_against_event,
    get_chat_list,
    get_chat_history_list,
    handleSetIsChatShow,
    handleSetEventSlug,
    handleSetSelectedChat,
    get_all_unread_count,
    mark_as_read,
    mark_as_unread,
    handleUpdateChatObject,
    STOP_CHAT_FORCEFULLY,
    handleCloseChat,
    setChatList,
    setSelectedChat,
    loadMoreData,
    lastBookElementRef,
    setSelectedMessage,
    setMessagesList,
    get_formatted_message,
    scroll_to_bottom_on_message,
    handleAddChat,
    setwhichChatListApiCall,
    isLoadingMore,
    isLoadingMoreChats,
    setEventApiObject,
    eventApiObject,
    onlineOfflineData,
    setCount,
    count,
    chatList,
    chatMessages,
    messagesList,
    selectedChat,
    selectedUser,
    selectedMessage,
    selectedChatUser,
    loadMoreChatPath,
    totalPagesChats,
    pageNumberChat,
    setOfflinePortalMembers,
    setOnlinePortalMembers,
    setOnlinePortalMembersCount,
    setOfflinePortalMembersCount,
    unreadChatCounts,
    setIsLoadingMoreChats,
    isLoadingChats,
    isLoadingChat,
    setSelectedChatUser,
  };

  return (
    <CreateChatContext.Provider value={collection}>
      {/* pass children */}
      {children}
      {/* ------------- */}

      <>
        {/* user listing chat */}
        {isChatShow && (
          <UserBox
            users_list={[...onlineMembers, ...offlineMembers]}
            online_members={[...onlinePortalMembers]}
            offline_members={[...offlinePortalMembers]}
            handleSetSelectedChat={handleSetSelectedChat}
            handleSetSelectedUser={handleSetSelectedUser}
            get_users_list_against_event={get_users_list_against_event}
            get_more_users_list_against_event={
              get_more_users_list_against_event
            }
            onlineMembersCount={onlinePortalMembersCount}
            offlineMembersCount={offlinePortalMembersCount}
            totalPages={totalPages}
            pageNumber={pageNumber}
            loadMorePath={loadMoreUserPath}
            isLoadingUsers={isLoadingUsers}
            setPageNumber={setPageNumber}
          ></UserBox>
        )}
        {/* chat popup */}
        {(selectedChat || selectedUser) && !is_chat_screen_open() && (
          <div
            className="user-chat-box-wrapper"
            style={
              isChatShow
                ? {}
                : {
                    right: 10,
                  }
            }
          >
            <ChatBox
              selectedUser={selectedUser}
              selectedChat={selectedChat}
              handleCloseChat={handleCloseChat}
              chatMessages={chatMessages}
              setChatMessages={setChatMessages}
              setSelectedChat={setSelectedChat}
              handleUpdateChatObject={handleUpdateChatObject}
            ></ChatBox>
          </div>
        )}
      </>
    </CreateChatContext.Provider>
  );
}

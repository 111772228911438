import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

// material
import { styled } from "@mui/material/styles";
import {
  Card,
  Stack,
  Link,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
//

import {
  consultantSettingApi,
  login,
  loginAsCustomerApi,
  login_by_admin_api,
  witoutTokeninitapi,
} from "../DAL/Login/Login";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
import AuthSocial from "../components/authentication/AuthSocial";
import { get_app_headers, project_name } from "../config/config";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { GetProfileApi } from "src/DAL/Profile/Profile";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function LoginByAdmin() {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const {
    userInfo,
    handleSetUserInfo,
    handleAdminTimeZone,
    setUserProfile,
    setAdminTimeZone,
    handleConsultantInfo,
    handleWheelOfLifeSetting,
    START_SOCKET_FORCEFULLY,
    isLocal,
  } = useContentSetting();

  // const getContentSetting = async () => {
  //   const result = await consultantSettingApi();
  //   if (result.code == 200) {
  //     handleConsultantInfo(result.consultant_setting);
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //   }
  // };
  const getContentSetting = async () => {
    const result = await witoutTokeninitapi();
    if (result.code == 200) {
      handleConsultantInfo(result.consultant_setting);
      handleWheelOfLifeSetting(result.wheel_of_life_setting);
      // setProjectData(result.consultant_setting);
      localStorage.setItem(
        `consultant_data`,
        JSON.stringify(result.consultant_setting)
      );
      localStorage.setItem(
        `wheel_of_life_setting`,
        JSON.stringify(result.wheel_of_life_setting)
      );
      // setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      // setLoading(false);
    }
  };

  const consultantProfile = async () => {
    const result = await GetProfileApi(userInfo._id);
    if (result.code === 200) {
      localStorage.setItem("admin_time_zone", JSON.stringify(result.time_zone));
      setUserProfile(result.consultant);
      handleSetUserInfo(result.consultant);
      handleAdminTimeZone(result.time_zone);
      setAdminTimeZone(result.time_zone);
    }
  };

  const handleSubmitLogin = async () => {
    setIsLoading(true);
    const data = {
      token: params.token,
      user_type: "consultant",
    };
    const result = await login_by_admin_api(data);
    console.log(result, "login result");
    if (result.code === 200) {
      handleSetUserInfo(result.consultantUser);
      START_SOCKET_FORCEFULLY(result.consultantUser._id);
      handleAdminTimeZone(result.time_zone);
      console.log(result, "localstorageData");
      localStorage.setItem(`user_data`, JSON.stringify(result.consultantUser));
      // JSON.parse(localStorage.getItem(`project_${id}`)).filter,
      localStorage.setItem("token", result.token);
      if (result?.access_object?.other_link && !isLocal) {
        window.location.href = result?.access_object?.other_link;
      } else {
        navigate("/dashboard", { replace: true });
      }
      getContentSetting();
      consultantProfile();
    } else {
      enqueueSnackbar("Invalid Credentials", { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleSubmitLogin();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
}

import React from "react";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export const UpdatesFilter = ({
  filterData,
  setFilterData,
  searchFunction,
  onClear,
}) => {
  const handleChangeDate = (name, event) => {
    setFilterData((old) => ({ ...old, [name]: event.$d }));
  };

  return (
    <div className="container">
      <form onSubmit={searchFunction}>
        <div className="row">
          <div className="col-12">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Start Date"
                name="start_date"
                inputFormat="DD-MM-YYYY"
                value={filterData.start_date}
                format="DD-MM-YYYY"
                className="mt-3"
                onChange={(e) => handleChangeDate("start_date", e)}
                renderInput={(params) => (
                  <TextField {...params} required={true} />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="End Date"
                name="end_date"
                inputFormat="DD-MM-YYYY"
                value={filterData.end_date}
                format="DD-MM-YYYY"
                className="mt-3"
                onChange={(e) => handleChangeDate("end_date", e)}
                renderInput={(params) => (
                  <TextField {...params} required={true} />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 mt-3 text-end">
            <button className="small-contained-button me-2" onClick={onClear}>
              Clear Filter
            </button>
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
};

import { TextField, TextareaAutosize } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import {
  addPersonalNote,
  sendNotificationUserApi,
} from "src/DAL/member/Member";
export default function NotifyUsersPopup({
  selectedObject,
  setOpen,
  bookingsData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const [NotificationsSetting, setNotificationsSetting] = useState({
    notification_statement: "",
    notification_description: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setNotificationsSetting((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      notification_statement: NotificationsSetting?.notification_statement,
      notification_description: NotificationsSetting?.notification_description,
    };
    const result = await sendNotificationUserApi(postData, selectedObject._id);
    if (result.code === 200) {
      setOpen(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
          <>
            <div className="">
              <div className="mb-2">
                <TextField
                  variant="outlined"
                  id="time"
                  label="Notification Statement"
                  type="text"
                  className="mt-1"
                  size="small"
                  name="notification_statement"
                  required={true}
                  value={NotificationsSetting.notification_statement}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder={`Notification Description *`}
                className="text-color mt-2"
                required
                value={NotificationsSetting?.notification_description}
                minRows={3}
                name="notification_description"
                onChange={(e) => handleChange(e)}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  background: "transparent",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  paddingLeft: "15px",
                  color: "white",
                  fontSize: "15px",
                  border: "1px solid var(--input-border-color)",
                }}
              />
            </div>
          </>
        </div>

        <div class="col-12 mt-3">
          <button class="small-contained-button w-100">
            {isLoading ? "Please Wait..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}

import React from "react";
import { s3baseUrl } from "src/config/config";
import UserInfoCard from "./UserInfoCard";

export default function UsersList({
  commentLikes,
  totalPages,
  pageNumber,
  lastBookElementRef,
  loadMoreData,
  isLoadingMore,
}) {
  console.log(commentLikes, "commentLikescommentLikescommentLikes");
  return (
    <div className="row feed-activity-box">
      {commentLikes.map((activity, index) => {
        return (
          <div className="col-12 mt-3" key={index}>
            <UserInfoCard
              profile_image={s3baseUrl + activity.action_info.profile_image}
              user_name={
                activity.action_info.first_name +
                " " +
                activity.action_info.last_name
              }
              avatar_char={activity.action_info.first_name[0]}
              date={activity.action_date_time}
              badgeLevel={activity?.badge_info}
              activity_type="like"
            />
          </div>
        );
      })}
      {totalPages > pageNumber ? (
        <div className="col-12 text-center">
          <span
            onClick={loadMoreData}
            className="load-more-text"
            ref={lastBookElementRef}
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

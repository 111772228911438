import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  _get_portal_updates,
  _list_portal_updates,
} from "src/DAL/Updates/Updates";
import { api_date_format, dd_date_format } from "src/utils/constants";
import { makeStyles } from "@mui/styles";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import { UpdatesFilter } from "src/components/Updates/UpdatesFilter";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_FILTER = {
  start_date: null,
  end_date: null,
};

export default function ScrollSidebar() {
  const observerRefs = useRef({});
  const observer = useRef();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [activeSection, setActiveSection] = useState("");
  const [updatesList, setUpdatesList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loadMorePath, setLoadMorePath] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);

  const handleOpenDrawer = () => {
    setFilterDrawer(true);
  };

  const handleCloseDrawer = () => {
    setFilterDrawer(false);
  };

  const getUpdatesListing = async ({ path, filter_data }) => {
    let postData = { search: searchText };

    if (filter_data?.start_date) {
      postData.start_date = api_date_format(filter_data.start_date);
    }
    if (filter_data?.end_date) {
      postData.end_date = api_date_format(filter_data.end_date);
    }

    const result = await _get_portal_updates(postData, path);
    if (result.code === 200) {
      if (path) {
        setUpdatesList((old) => [...old, ...result.portal_updates]);
      } else {
        setUpdatesList(result.portal_updates);
      }
      setLoadMorePath(result.load_more);
      setIsLoading(false);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && loadMorePath) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, loadMorePath, observer]
  );

  const loadMoreData = () => {
    setIsLoadingMore(true);
    if (loadMorePath) {
      getUpdatesListing({ path: loadMorePath });
    }
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    handleCloseDrawer();
    setIsLoading(true);
    setFilterData(EMPTY_FILTER);
    getUpdatesListing({ filter_data: EMPTY_FILTER });
  };

  const searchFunction = (e) => {
    e.preventDefault();
    filterData.search_text = searchText;
    handleCloseDrawer();
    setIsLoading(true);
    getUpdatesListing({ filter_data: filterData });
  };

  useEffect(() => {
    if (updatesList?.length < 1 || isLoading) return;
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: "0px",
      threshold: 0.3, // Lowered for mobile sensitivity
    });

    updatesList.forEach((section) => {
      const element = document.getElementById(section._id);
      if (element) {
        observer.observe(element);
        observerRefs.current[section._id] = element;
      }
    });

    return () => {
      updatesList.forEach((section) => {
        const element = observerRefs.current[section._id];
        if (element) observer.unobserve(element);
      });
    };
  }, [updatesList, isLoading]);

  useEffect(() => {
    getUpdatesListing({ filter_data: EMPTY_FILTER });
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const handleClick = (id) => {
    setActiveSection(id);
    const container = document.querySelector(".content-updates");
    const element = document.getElementById(id);
    if (container && element) {
      container.scrollTo({
        top: element.offsetTop - container.offsetTop,
      });
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 page-heading my-2">
            <h2>Updates</h2>
          </div>
          <div className="col-6 text-end">
            <button
              className="small-contained-button my-2"
              onClick={handleOpenDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        {updatesList.length > 0 ? (
          <div className="row portal-updates-container">
            {/* Sidebar */}
            <nav className="col-md-3 position-sticky top-0 rounded sidebar-updates">
              <ul className="nav flex-column">
                {updatesList.map((section) => (
                  <li key={section._id} className="nav-item">
                    <button
                      className={`nav-link w-100 text-start ${
                        activeSection === section._id ? "active " : ""
                      }`}
                      onClick={() => handleClick(section._id)}
                    >
                      {section.title}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>

            {/* Scrollable Content */}
            <div className="col-md-9 overflow-auto content-updates mt-3 mt-md-0">
              {updatesList.map((section) => (
                <div
                  id={section._id}
                  key={section._id}
                  className="p-3 rounded shadow mb-3 bg-white section-card-item"
                >
                  <div className="d-flex justify-content-between">
                    <h2 className="update-title">{section.title}</h2>
                    <p className="date-color">{dd_date_format(section.date)}</p>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: section.fixed_issues_description,
                    }}
                  ></div>
                </div>
              ))}
              {loadMorePath && (
                <div className="col-12 text-center">
                  <button
                    ref={lastBookElementRef}
                    className="small-contained-button mt-3"
                  >
                    {isLoadingMore ? "Loading..." : "Load More"}
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="mt-5">
            <RecordNotFound title="Updates not found" />
          </div>
        )}
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={"Updates Filter"}
        componentToPassDown={
          <UpdatesFilter
            filterData={filterData}
            setFilterData={setFilterData}
            searchFunction={searchFunction}
            onClear={handleClearFilter}
          />
        }
      />
    </>
  );
}

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import ReactVideoPlayer from "src/components/_dashboard/GeneralComponents/ReactVideoPlayer";
import GeneralLiveChat from "src/components/GeneralComponents/GeneralLiveChat/GeneralLiveChat";
import MissionQuestSchedules from "../MissionQuestSchedules";

export default function QuestDashboard(props) {
  const { missionDetail, selectedSchedule } = props;

  const { mission_id, level_id } = useParams();
  const navigate = useNavigate();

  const handleClick = (schedule) => {
    navigate(
      `/mission-levels/${level_id}/quest/${mission_id}/schedule/${schedule._id}`,
      {
        state: schedule,
      }
    );
  };

  const TABS_DATA = {
    handleClick,
    schedules: missionDetail.mission_schedules,
    missionDetail,
    missionSchedules: {},
  };

  const handle_get_class = () => {
    let classes = "";
    if (selectedSchedule) {
      if (selectedSchedule.is_chat_enabled) {
        classes = "col-lg-8";
      }
    } else {
      if (missionDetail.is_chat_enabled) {
        classes = "col-lg-8";
      }
    }
    return classes;
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row mt-2">
          <div className={`col-12 ${handle_get_class()}`}>
            <div id="video-col">
              {selectedSchedule ? (
                <div id="video_detail_image_box video-col">
                  {selectedSchedule.embed_code ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedSchedule.embed_code,
                      }}
                    ></div>
                  ) : selectedSchedule.video_url ? (
                    <ReactVideoPlayer url={selectedSchedule.video_url} />
                  ) : (
                    <img
                      src={s3baseUrl + selectedSchedule.image?.thumbnail_1}
                      className="img-fluid w-100"
                    />
                  )}
                </div>
              ) : (
                <div id="video_detail_image_box video-col">
                  {missionDetail.video_url ? (
                    <ReactVideoPlayer url={missionDetail.video_url} />
                  ) : (
                    <img
                      src={s3baseUrl + missionDetail.image?.thumbnail_1}
                      className="img-fluid w-100"
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          {selectedSchedule
            ? selectedSchedule?.is_chat_enabled && (
                <GeneralLiveChat
                  module_id={selectedSchedule._id}
                  chat_created_for="quest_day"
                />
              )
            : missionDetail?.is_chat_enabled && (
                <GeneralLiveChat
                  module_id={missionDetail._id}
                  chat_created_for="quest"
                />
              )}
          {missionDetail.mission_schedules.length > 0 && (
            <div className="col-md-12  mt-3">
              <div className="row">
                <div
                  className="col-12 schedule-heading pb-0"
                  id="schedule-heading-box"
                >
                  <h2 style={{ fontSize: "20px" }} className="mt-2 mb-2">
                    {missionDetail.content_settings?.schedule_heading}
                  </h2>
                </div>
              </div>
              <MissionQuestSchedules {...TABS_DATA} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

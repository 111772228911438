import { useEffect, useState } from "react";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CopyToClipboard from "react-copy-to-clipboard";
import { _get_link_list_by_clickfunnels_api } from "src/DAL/CampaignURL/CampaignURL";
import { useContentSetting } from "src/Hooks/ContentSettingState";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CampaignURL() {
  const { userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [campaignURLData, setCampaignURLData] = useState([]);

  const handleCopiedUrl = () => {
    enqueueSnackbar("Url copied to clipboard", { variant: "success" });
  };

  const getCampaignURLPageListing = async (search) => {
    setIsLoading(true);

    const result = await _get_link_list_by_clickfunnels_api();
    if (result.code === 200) {
      let result_array = [];

      result.data?.map((template) => {
        result_array.push({
          ...template,
          preview_link_data: {
            to:
              template?.url +
              "?affliate_url_name=" +
              userInfo?.affiliate_url_name,
            target: "_blank",
            show_text: "Preview",
            className: "anchor-style",
          },
        });
      });
      setCampaignURLData(result_array);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCampaignURLPageListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "name", label: "Name", alignRight: false },
    { id: "type", label: "Type", alignRight: false, className: "capitalaize" },
    {
      id: "sale_page_title1",
      label: "Copy URL",
      alignRight: false,
      renderData: (row) => {
        return (
          <CopyToClipboard
            text={
              row?.url + "?affliate_url_name=" + userInfo?.affiliate_url_name
            }
            onCopy={() => handleCopiedUrl(true)}
          >
            <Tooltip title="Click to copy Preview URL">
              <Chip label="Copy URL" color="primary" variant="outlined" />
            </Tooltip>
          </CopyToClipboard>
        );
      },
    },
    {
      id: "preview_link_data",
      label: "URL",
      type: "link",
      alignRight: false,
    },
  ];

  return (
    <>
      <div className="container">
        <div className="row section-space">
          <div className="col-sm-12 col-md-6 ">
            <h2>Campaign URL</h2>
          </div>
        </div>

        <div className="mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={campaignURLData}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
